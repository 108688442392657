import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import metaData from 'utils/metaData'

const Meta = () => {
  const location = useLocation()
  const meta = metaData[location.pathname] || {
    title: 'Bharatham - Shop Directly from Manufacturers | Electronics, Fashion & More',
    description:
      'Shop a wide range of products on Bharatham, from electronics to fashion, all directly from manufacturers. Enjoy unbeatable prices, fast delivery, and a seamless shopping experience.',
  }

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="title" content={meta.title} />
      <meta name="description" content={meta.description} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:url" content={window.location.href} />
    </Helmet>
  )
}

export default Meta
