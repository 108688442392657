import { makeStyles } from '@mui/styles'
import { styled } from 'styled-components'
import palette from '../../../utils/Theme/palette'
import { theme } from 'styles/Theme/theme'

const useStyles = makeStyles({
  topBrandsContainer: {
    overflow: 'hidden',
    width: 'unset !important',
  },
  brandsContainer: {
    display: 'flex',
    gap: '25px',
    overflow: 'auto',
    paddingBottom: '10px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  paginationActionContainer: {
    display: 'flex',
    gap: '25px',
  },
  brandCard: {
    cursor: 'pointer',
    height: '171px !important',
    width: '171px !important',
    minWidth: '171px !important',
    borderRadius: '24.5px !important',
    background: '#F5F5F5 !important',
    display: 'flex',
    boxShadow: 'none !important',
    marginRight: '40px',
  },
  brandImage: {
    margin: 'auto !important',
    maxWidth: '100%',
  },
  dotsContainer: {
    textAlign: 'center',
  },
  dot: {
    height: '10px',
    width: '10px',
    backgroundColor: '#D9D9D9',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: '5px',
  },
  selectedDot: {
    height: '10px',
    width: '20px',
    backgroundColor: palette.primary.main,
    borderRadius: '10px',
    display: 'inline-block',
    marginLeft: '5px',
  },
  categoriesContainer: {
    '& ul': {
      overflow: 'auto',
      display: 'flex',
      gap: '10px',
      // padding: '16px !important',
      justifyContent: 'center',
      '& li:has(.MuiPaginationItem-ellipsis)': {
        background: 'red !important',
        display: 'none',
      },
    },
    textAlign: 'center',
    // margin: 'auto'
  },
  isActive: {
    border: `2px solid ${palette.primary.main}`,
  },
})

export default useStyles

export const TopBrandsContainer = styled.div`
  background: ${theme.PRIMARYCOLOR};
  color: ${(props) => (props.color === 'light' ? props.theme.secondaryColor : props.theme.primaryColor)};
  .MuiGrid-item {
    padding-top: 0 !important;
    padding-left: 0 !important;
  }
  .MuiGrid-root {
    margin: 5px 0;
  }
  padding: 20px 40px;
  @media (max-width: 450px) {
    padding: 20px 0;
  }
`
export const ProvidersCard = styled.div`
  cursor: pointer;
  height: 150px;
  width: 140px;
  min-width: 140px;

  border-radius: 24.5px;
  overflow: hidden;
  background: #ffffff14;
  // background: ${theme.TERTIARYBACKGROUNDCOLOR};
  border: 1px solid #ffffff80;
  display: flex;
  margin-right: 40px;
  @media (max-width: 450px) {
    height: 130px;
    width: 130px;
    min-width: 130px;
    margin-right: 20px;
  }
`
