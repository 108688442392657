import React, { useContext, useState } from 'react'
import ErrorMessage from '../../shared/error-message/errorMessage'
import Input from '../../shared/input/input'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { Link, useHistory } from 'react-router-dom'
import { toast_actions, toast_types } from '../../shared/toast/utils/toast'
import { ToastContext } from '../../../context/toastContext'
import {
  PageWrapper,
  ImageContainer,
  LogoContainer,
  FormContainer,
  Title,
  FormWrapper,
  // Label,
  SignUpText,
  AccountText,
  ButtonContainer,
  NavigationLinks,
} from '../../../styles/auth/Login'
import Logo from '../../../assets/images/BharathamLogo.png'
import { Button as NewButton } from '@mui/material'

const ForgetPassword = () => {
  const auth = getAuth()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const dispatch = useContext(ToastContext)
  const [inlineError, setInlineError] = useState({
    email_error: '',
  })

  // use this function to check the email
  function checkEmail() {
    if (!email) {
      setInlineError((inlineError) => ({
        ...inlineError,
        email_error: 'Email cannot be empty',
      }))
      return false
    }

    return true
  }

  async function resetPassword(e) {
    e.preventDefault()
    if (!checkEmail()) {
      return
    }

    try {
      // Send a password reset email to the user's email address
      await sendPasswordResetEmail(auth, email)
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: 'Password reset email sent. Please check your email.',
        },
      })
      history.push('/login')
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error.message,
        },
      })
    }
  }

  return (
    <PageWrapper>
      <FormContainer>
        <LogoContainer>
          <Link to="/">
            <img src={Logo} alt="Bharatham" />
          </Link>
        </LogoContainer>
        <FormWrapper>
          <form onSubmit={resetPassword}>
            <Title>Forget Password</Title>
            <Input
              id="email"
              name="email"
              type="email"
              placeholder="Enter Email"
              label_name="Email"
              autoComplete="off"
              has_error={inlineError.email_error}
              onChange={(event) => {
                setEmail(event.target.value)
                setInlineError((inlineError) => ({
                  ...inlineError,
                  email_error: '',
                }))
              }}
              onBlur={checkEmail}
              required
            />
            {inlineError.email_error && <ErrorMessage>{inlineError.email_error}</ErrorMessage>}
            <ButtonContainer>
              <NewButton className="login_button" variant="contained" type="submit">
                Submit
              </NewButton>
            </ButtonContainer>
            <NavigationLinks>
              <AccountText>Already have an account ?</AccountText>
              <Link to="/login">
                <SignUpText>Sign In</SignUpText>
              </Link>
            </NavigationLinks>
          </form>
        </FormWrapper>
      </FormContainer>
      <ImageContainer>
      </ImageContainer>
    </PageWrapper>
  )
}

export default ForgetPassword
