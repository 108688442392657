import * as React from 'react'
const SvgComponent = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={24} height={24} rx={4} fill="#FF671F" />
    <path
      d="M7.58185 12.1814L12 16.5995L16.4182 12.1814C17.5273 11.0722 17.5273 9.27398 16.4182 8.16485C15.309 7.05573 13.5108 7.05573 12.4017 8.16485L12 8.5665L11.5984 8.16485C10.4892 7.05573 8.69097 7.05573 7.58185 8.16485C6.47272 9.27398 6.47272 11.0722 7.58185 12.1814Z"
      fill="white"
      stroke="white"
      strokeWidth={0.875}
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
