import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import MuiModel from '@mui/material/Modal'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { styled } from '@mui/material/styles'
import Backdrop from '@mui/material/Backdrop'
import { ModalContent, HeaderSection } from 'styles/ModalComponent'
import { theme } from 'styles/Theme/theme'

const style = {
  position: 'absolute',
  overflow: 'auto',
  display: 'block',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '70%',
  backgroundColor: 'background.paper',
  border: `1px solid ${theme.TERTIARYBORDERCOLOR}`,
  borderRadius: '8px',
  '@media (max-width: 500px)': {
    width: '95%', // Adjust according to your needs for smaller screens
  },
  // p: 2,
  // pt: 2
}

const styleFullscreen = {
  position: 'fixed',
  overflow: 'auto',
  display: 'flex',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '100%',
  maxHeight: '100%',
  minHeight: '100%',
  backgroundColor: 'background.paper',
  border: `1px solid ${theme.TERTIARYBORDERCOLOR}`,
  borderRadius: '0px',
  flexDirection: 'column',

  // p: 4,
  // pt: 2
}
const styleHeader = {
  backgroundColor: 'background.paper',
  p: 3,
  borderBottom: `1px solid ${theme.MODALBORDERCOLOR} !important`,
}
const styleContainer = {
  backgroundColor: 'background.paper',
  p: 2,
  mt: 0,
  overflowY: 'auto',
  position: 'relative',
}
const styleFullScreenContainer = {
  backgroundColor: 'background.paper',
  p: 3,
  mt: 2,
  mb: 6,
  overflowY: 'auto',
}
const Modal = styled(MuiModel)(() => ({
  overflow: 'hidden',
}))

const BlurredBackground = styled('div')`
  backdrop-filter: blur(2px); /* Adjust blur strength as needed */
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; /* Ensure it's above other content */
`

const ModalComponent = ({ children, title, open, onClose, className, fullWidth = false, titleBg }) => {
  const modalStyle = className === 'mic_modal' ? { ...style, minWidth: '40%' } : style
  return (
    <div>
      {open && <BlurredBackground />}
      <Modal
        title={title}
        open={open}
        onClose={onClose}
        className={className}
        BackdropComponent={Backdrop}
        BackdropProps={{
          invisible: true,
        }}
      >
        <ModalContent>
          <Box sx={fullWidth ? styleFullscreen : modalStyle} className="modal-component">
            {title && (
              <HeaderSection className="modal_title">
                <Box className={titleBg ? 'buy_now' : 'modal_header'} sx={styleHeader}>
                  <Typography variant="h6" component="p">
                    {title}
                  </Typography>
                  {onClose && <CloseRoundedIcon onClick={onClose} />}
                </Box>
              </HeaderSection>
            )}
            <Box sx={fullWidth ? styleFullScreenContainer : styleContainer}>{children}</Box>
          </Box>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default ModalComponent
