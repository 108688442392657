import React, { useContext, useEffect, useState } from 'react'
import useStyles, {
  Container,
  OutletSection,
  OutletCardSection,
  DetailsSection,
  StoreName,
  StoreLocation,
  StoreTiming,
  Wrapper,
  OutletContactInfoSection,
  ContactInfoTitle,
  ContactInfoDescription,
  ContactInfoButtonSection,
} from './style'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import MuiLink from '@mui/material/Link'
import Box from '@mui/material/Box'
import moment from 'moment'
import { Link } from 'react-router-dom'
import no_image_found from '../../../assets/images/no_image_found.png'
import CustomMenu from './customMenu/customMenu'
import Button from '@mui/material/Button'
// import PlacePickerMap from '../../common/PlacePickerMap/PlacePickerMap'
// import ViewOnlyMap from '../../common/ViewOnlyMap/ViewOnlyMap'
import { ToastContext } from 'context/toastContext'
import { toast_actions, toast_types } from '../../shared/toast/utils/toast'
import { getBrandDetailsRequest, getOutletDetailsRequest } from '../../../api/brand.api'
import useCancellablePromise from '../../../api/cancelRequest'
import { SearchContext } from '../../../context/searchContext'
import ModalComponent from '../../common/Modal'

const OutletDetails = (props) => {
  const { brandId, outletId } = props
  const classes = useStyles()
  // const history = useHistory()
  const { locationData: deliveryAddressLocation } = useContext(SearchContext)

  const [brandDetails, setBrandDetails] = useState(null)
  const [outletDetails, setOutletDetails] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  isLoading
  const [callNowModal, setCallNowModal] = useState(false)

  // HOOKS
  const { cancellablePromise } = useCancellablePromise()
  const dispatch = useContext(ToastContext)

  const getBrandDetails = async () => {
    setIsLoading(true)
    try {
      const data = await cancellablePromise(getBrandDetailsRequest(brandId))
      setBrandDetails(data)
      if (outletId) {
        await getOutletDetails()
      }
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  const getOutletDetails = async () => {
    setIsLoading(true)
    try {
      let data = await cancellablePromise(getOutletDetailsRequest(outletId))
      data.timings = ``
      data.isOpen = false
      data.circle.gps = data.circle.gps.split(',')
      data.circle.gps = {
        lat: data.circle.gps[0],
        lng: data.circle.gps[1],
      }
      if (data.time.range.start && data.time.range.end) {
        data.timings = `${moment(data.time.range.start, 'hhmm').format('h:mm a')} - ${moment(
          data.time.range.end,
          'hhmm',
        ).format('h:mm a')}`
        const time = moment(new Date(), 'hh:mm')
        const startTime = moment(data.time.range.start, 'hh:mm')
        const endTime = moment(data.time.range.end, 'hh:mm')
        data.isOpen = time.isBetween(startTime, endTime)
      }

      setOutletDetails(data)
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (brandId) {
      getBrandDetails()
    }
  }, [brandId, outletId, deliveryAddressLocation])

  return (
    <Container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <MuiLink component={Link} underline="hover" color="inherit" to="/">
              Home
            </MuiLink>
            <MuiLink component={Link} underline="hover" color="inherit" to={`/brand?brandId=${brandId}`}>
              {brandDetails?.descriptor?.name}
            </MuiLink>
            {brandId && <Typography color="text.primary">{`${brandDetails?.descriptor?.name} `}</Typography>}
          </Breadcrumbs>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.outletDetailsHeaderContainer}>
        <OutletSection>
          <OutletCardSection>
            <img
              src={brandDetails?.descriptor?.symbol ? brandDetails?.descriptor?.symbol : no_image_found}
              alt={brandDetails?.descriptor?.name}
              onError={(e) => {
                e.target.src =
                  'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
              }}
            />
          </OutletCardSection>
          <DetailsSection>
            <StoreName variant="h2">{brandDetails?.descriptor?.name}</StoreName>
            <StoreLocation>{outletDetails?.description}</StoreLocation>
            <StoreLocation>
              {`${
                outletDetails?.address
                  ? `${outletDetails?.address?.street || '-'}, ${outletDetails?.address?.city || '-'}`
                  : '-'
              }`}
            </StoreLocation>
            <StoreTiming>
              {outletDetails?.isOpen && <span className={classes.isOpen}>Open now</span>}
              {outletDetails?.isOpen ? ` :` : ''} {outletDetails?.timings}
            </StoreTiming>
          </DetailsSection>
        </OutletSection>
        <Grid container spacing={2}>
          <Wrapper>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Box component={'div'} className={classes.divider} />
              <CustomMenu brandId={brandId} brandDetails={brandDetails} outletDetails={outletDetails} />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={4} lg={4} xl={4}> */}
            <OutletContactInfoSection>
              {/* <Typography variant="h4">Call</Typography>
              <Typography component="div" variant="body" className={classes.contactNumbers}>
                +91 92729282982, +91 92729282982
              </Typography> */}
              <ContactInfoTitle>Direction</ContactInfoTitle>
              <ContactInfoDescription>
                {`${
                  outletDetails?.address
                    ? `${outletDetails?.address?.street || '-'}, ${outletDetails?.address?.city || '-'}`
                    : '-'
                }`}
              </ContactInfoDescription>
              <ContactInfoButtonSection>
                <Button variant="contained">Get Direction</Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setCallNowModal(true)
                  }}
                >
                  Call Now
                </Button>
              </ContactInfoButtonSection>
            </OutletContactInfoSection>
            {/* </Grid> */}
          </Wrapper>
        </Grid>
      </Grid>
      <ModalComponent
        open={callNowModal}
        onClose={() => {
          setCallNowModal(false)
        }}
        title="Call Now"
      >
        <Typography component="div" variant="body" className={classes.contactNumbers}>
          +91 92729282982, +91 92729282982
        </Typography>
      </ModalComponent>
    </Container>
  )
}

export default OutletDetails
