import { makeStyles } from '@mui/styles'
import { styled } from 'styled-components'
import palette from '../../../utils/Theme/palette'

const useStyles = makeStyles({
  categoriesContainer: {
    background: '#F9F9F9',
    display: 'flex',
    gap: '29px',
    padding: '16px !important',
    justifyContent: 'center',
  },
  categoryItem: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  categoryItemImageContainer: {
    height: '108px',
    width: '108px',
    borderRadius: '8px',
    background: '#fff',
    display: 'flex',
    margin: 'auto',
    border: '1px solid #E7E7E7',
  },
  categoryImage: {
    height: '78px',
    maxWidth: '78px',
    minWidth: '42px',
    margin: 'auto',
  },
  categoryNameTypo: {
    fontWeight: '500 !important',
    fontSize: '16px !important',
    marginTop: '5px !important',
    textAlign: 'center',
  },
  selectedCategory: {
    border: `2px solid  ${palette.primary.main}`,
  },
})

export default useStyles

export const CategoryItem = styled.div`
  text-align: center;
  cursor: pointer;
  color: ${({ theme }) => theme.secondaryColor};
`
