import * as React from 'react'
const SvgComponent = (props) => (
  <svg width={9} height={16} viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.5 15L1.20711 8.70711C0.816582 8.31658 0.816583 7.68342 1.20711 7.29289L7.5 1"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
