import styled from 'styled-components'

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  select {
    padding: 10px;
    width: 100%;
    max-width: 90%;
  }
  input {
    padding: 5px;
  }
  .uploadLabel {
    color: #3a3a88;
    text-decoration: underline;
    cursor: pointer;
  }
`
export const TitleContainer = styled.div`
  background: #3a3a88;
  padding: 20px;
  text-align: center;
  color: #fff;
`
export const ThemeContainer = styled.div`
  .label {
    color: #393939;
    margin-top: 20px;
    font-size: 16px;
  }
`
export const ButtonContainer = styled.div`
  text-align: center;
  display: flex;
  gap: 20px;
  button {
    width: 100%;
    max-width: 200px;
    margin: 20px auto;
    text-transform: capitalize;
  }
`
export const UpdatedValue = styled.div`
  text-transform: Capitalize;
`
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 20px;
`
export const UploadWrapper = styled.div`
  text-align: center;
  > img {
    max-width: 100px;
    max-height: 100px;
  }
`
