import React, { useContext, useEffect, useState } from 'react'
import useStyles from '../../../components/orders/orderDetails/style'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import MuiLink from '@mui/material/Link'
import Button from '@mui/material/Button'
// import Tooltip from '@mui/material/Tooltip'
// import Card from '@mui/material/Card'
// import Chip from '@mui/material/Chip'
import moment from 'moment'
import Box from '@mui/material/Box'
import { Link, useLocation } from 'react-router-dom'
import { ToastContext } from '../../../context/toastContext'
import Loading from '../../shared/loading/loading'
// import { ISSUE_TYPES } from '../../../constants/issue-types'
// import useCancellablePromise from '../../../api/cancelRequest'
import { toast_actions, toast_types } from '../../shared/toast/utils/toast'
import {} from // getSelectCall,
// postCheckoutCall
'../../../api/axios'
// import { getValueFromCookie } from '../../../utils/cookies'
// import { SSE_TIMEOUT } from '../../../constants/sse-waiting-time'
import CustomerActionCard from './action-card/actionCard'
import Timeline from '@mui/lab/Timeline'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { ReactComponent as TimelineIcon } from '../../../assets/images/timeline.svg'
import { compareDateWithDuration, parseDuration } from '../../../utils/helper'
import ProductImage from 'assets/images/no_image_found.png'
import {
  ComplaintsDetailMainWrapper,
  RespondentDetailsWrapper,
  RespondentTitle,
  RespondentInfoWrapper,
  TimelineContentWrapper,
  ComplaintStatus,
  ComplaintTime,
  ComplaintStatusDescription,
  ComplaintDescriptionWrapper,
  ComplaintRespondentDetail,
  ComplaintSummaryCardWrapper,
  ProductDetailSection,
  ProductImageWrapper,
  ProductName,
  ProductQuantityWrapper,
  ProductQuantityTitle,
  ProductQuantity,
  IssueImageWrapper,
  CardWrapper,
  CardDetailWrapper,
  ComplaintsDetailTitle,
  ComplaintsSection,
  ComplaintIssueSection,
  ComplaintTimelineWrapper,
  ComplaintStatusWrapper,
  ProductDetailWrapper,
  HeadingWrapper,
  Wrapper,
  ResolutionDetailWrapper,
  TimelineTitleWrapper,
  TimelineWrapper,
  TimelineTitle,
  ComplaintActionContainer,
  ActionButtonWrapper,
} from 'components/orders/orderCard/style'
import ModalComponent from 'components/common/Modal'
import useGet from 'hooks/useGet'
import GetStatusIcon from 'assets/svg/GetStatusIcon'
import { useParams } from 'react-router-dom'
const ComplaintDetail = () => {
  const classes = useStyles()
  const location = useLocation()
  const { data } = location.state || {}
  const dispatch = useContext(ToastContext)
  const { issueId } = useParams()
  //Api Call for single Issue

  const { data: getIssueData, refetch } = useGet('get-issue-details', `/issueApis/v1/issue?issueId=${issueId}`)
  const { issue } = getIssueData || {}
  useEffect(() => {
    refetch()
  }, [])

  const {
    order_details,
    description,
    // category,
    // sub_category,
    // issueId,
    issue_status,
    created_at,
    issue_actions,
    // transaction_id,
    // bppId,
    resolution,
    // message_id,
    // resolution_provider,
    // domain,
  } = issue || {}

  const newIssueId = issueId?.substring(0, 6)
  // STATES
  const [statusLoading, setStatusLoading] = useState(false)
  setStatusLoading
  const [toggleActionModal, setToggleActionModal] = useState(false)
  const [issueActions, setIssueActions] = useState([])
  const [issueStatus, setIssueStatus] = useState(issue_status)
  issueStatus
  // HELPERS
  // REFS
  // const cancelPartialEventSourceResponseRef = useRef(null)
  // const onIssueEventSourceResponseRef = useRef(null)
  // const eventTimeOutRef = useRef([])

  // CONTEXT

  // HOOKS
  // const { cancellablePromise } = useCancellablePromise()

  // const allCategory = ISSUE_TYPES.map((item) => {
  //   return item.subCategory.map((subcategoryItem) => {
  //     return {
  //       ...subcategoryItem,
  //       category: item.value,
  //     }
  //   })
  // }).flat()

  // use this function to dispatch error
  function dispatchToast(message, type) {
    dispatch({
      type: toast_actions.ADD_TOAST,
      payload: {
        id: Math.floor(Math.random() * 100),
        type,
        message,
      },
    })
  }

  useEffect(() => {
    mergeRespondantArrays(issue_actions)
    // fetchOnIssueDataThroughEvents()
  }, [issue])

  const mergeRespondantArrays = (actions) => {
    let resActions = actions?.respondent_actions ?? [],
      comActions =
        actions?.complainant_actions?.map((item) => {
          return { ...item, respondent_action: item.complainant_action }
        }) ?? [],
      mergedArray = [...comActions, ...resActions]

    mergedArray.sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at))
    setIssueActions(mergedArray)
  }

  const getComplaintStatus = () => {
    dispatch({
      type: toast_actions.ADD_TOAST,
      payload: {
        id: Math.floor(Math.random() * 100),
        type: toast_types.success,
        message: 'Complaint status updated successfully!',
      },
    })
    refetch()
  }

  function isShowTakeAction() {
    const lastAction = issueActions[issueActions.length - 1]?.respondent_action
    if (lastAction === 'PROCESSING' || lastAction === 'OPEN' || lastAction === 'ESCALATE') {
      return compareDateWithDuration(
        process.env.EXPECTED_RESPONSE_TIME ?? 'PT1H',
        issueActions[issueActions.length - 1]?.updated_at,
      )
    } else if (lastAction !== 'ESCALATE' && issueActions.some((x) => x.respondent_action === 'RESOLVED')) {
      return true
    } else {
      return false
    }
  }

  return (
    <ComplaintsDetailMainWrapper>
      {toggleActionModal && (
        <ModalComponent
          title="Take Action"
          titleBg={true}
          open={toggleActionModal}
          onClose={() => setToggleActionModal(false)}
        >
          <CustomerActionCard
            supportActionDetails={data}
            onClose={() => setToggleActionModal(false)}
            onSuccess={(actionData) => {
              dispatchToast(
                actionData[0].respondent_action === 'ESCALATE'
                  ? 'GRO would be reaching out to you soon'
                  : 'Action successfully taken',
                toast_types.success,
              )
              setToggleActionModal(false)
              setIssueActions([...issueActions, ...actionData])
              actionData[0].respondent_action === 'CLOSE' && setIssueStatus('Close')
            }}
            refetch={refetch}
            complaintData={getIssueData}
          />
        </ModalComponent>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div role="presentation">
          <Breadcrumbs aria-label="breadcrumb">
            <MuiLink component={Link} underline="hover" color="inherit" to="/">
              Home
            </MuiLink>
            <MuiLink component={Link} underline="hover" color="inherit" to="/profile/complaint">
              Complaint
            </MuiLink>
            {issueId && <Typography color="text.primary">Complaint Details</Typography>}
          </Breadcrumbs>
        </div>
      </Grid>
      <ComplaintsDetailTitle variant="h4">Complaint Details</ComplaintsDetailTitle>
      <ComplaintsSection>
        {issueActions?.some((x) => x.respondent_action !== 'OPEN') &&
          issue?.issue_actions?.respondent_actions?.length > 0 && (
            <RespondentDetailsWrapper>
              <>
                <RespondentTitle>Respondent Details</RespondentTitle>
                <RespondentInfoWrapper>
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                      Name
                    </Typography>
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      {issue_actions?.respondent_actions[issue_actions.respondent_actions.length - 1]?.updated_by
                        ?.person?.name ?? 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                      Phone
                    </Typography>
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      {issue_actions?.respondent_actions[issue_actions.respondent_actions.length - 1]?.updated_by
                        ?.contact?.phone ?? 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                      Email
                    </Typography>
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      {issue_actions?.respondent_actions[issue_actions.respondent_actions.length - 1]?.updated_by
                        ?.contact?.email ?? 'N/A'}
                    </Typography>
                  </Grid>
                </RespondentInfoWrapper>
              </>

              {issueActions.some((x) => x.respondent_action === 'ESCALATE') && (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                      Contact info of GRO
                    </Typography>
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      A Grievance has been raised. The GRO will be reaching out to you in 24 hours.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                      Name
                    </Typography>
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      {process.env.REACT_APP_BUYER_APP_GRO_NAME}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                      Phone
                    </Typography>
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      {process.env.REACT_APP_BUYER_APP_GRO_PHONE}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                      Email
                    </Typography>
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      {process.env.REACT_APP_BUYER_APP_GRO_EMAIL}
                    </Typography>
                  </Grid>
                </>
              )}
            </RespondentDetailsWrapper>
          )}
        {/* RESPONDENT ACTIONS  */}
        <ComplaintIssueSection>
          <ComplaintTimelineWrapper>
            <TimelineTitleWrapper>
              <TimelineTitle>Track Status</TimelineTitle>
              {issue_status !== 'Close' && (
                <ComplaintStatusWrapper onClick={getComplaintStatus}>
                  {statusLoading ? <Loading /> : <GetStatusIcon />}
                </ComplaintStatusWrapper>
              )}
            </TimelineTitleWrapper>
            {issueActions?.length > 0 && (
              <TimelineWrapper>
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  {issueActions?.map(({ respondent_action, short_desc, updated_at, updated_by }, itemIndex) => {
                    return (
                      <TimelineItem key={`timeline-index-${itemIndex}`}>
                        <TimelineSeparator>
                          <TimelineDot className={classes.timelineDot}>
                            <TimelineIcon className={classes.timelineIcon} />
                          </TimelineDot>
                          {issueActions?.length - 1 > itemIndex && (
                            <TimelineConnector className={classes.dottedConnector} />
                          )}
                        </TimelineSeparator>
                        <TimelineContent>
                          <TimelineContentWrapper>
                            <ComplaintStatus>{respondent_action}</ComplaintStatus>
                            <ComplaintTime
                              component="span"
                              variant="body1"
                              className={classes.timelineContentHeaderTimeTypo}
                            >
                              {moment(updated_at).format('MMMM Do, YYYY')} at {moment(updated_at).format('hh:mma')}
                            </ComplaintTime>
                          </TimelineContentWrapper>
                          <ComplaintDescriptionWrapper>
                            <ComplaintStatusDescription
                              component="div"
                              variant="body"
                              className={classes.timelineContentHeaderTypo}
                            >
                              {short_desc}
                            </ComplaintStatusDescription>
                            <ComplaintRespondentDetail>
                              {`Updated by: ${updated_by?.person?.name}`}
                            </ComplaintRespondentDetail>
                          </ComplaintDescriptionWrapper>
                        </TimelineContent>
                      </TimelineItem>
                    )
                  })}
                </Timeline>
              </TimelineWrapper>
            )}
          </ComplaintTimelineWrapper>

          {/* Complaint Summary */}
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <ComplaintSummaryCardWrapper>
              <div>
                {order_details?.items?.map(({ id, product, quantity }) => {
                  return (
                    <>
                      <div key={id} className="d-flex align-items-center pt-3">
                        <ProductDetailSection>
                          <Typography variant="h5" className={classes.orderNumberTypoBold}>
                            <ProductImageWrapper>
                              <img
                                src={product.descriptor.symbol ? product.descriptor.symbol : ProductImage}
                                alt={product.descriptor.name}
                                onError={(e) => {
                                  e.target.src =
                                    'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                                }}
                              />
                            </ProductImageWrapper>
                          </Typography>
                          <ProductDetailWrapper>
                            <HeadingWrapper>
                              <ProductQuantityTitle>{product.descriptor.name}</ProductQuantityTitle>
                            </HeadingWrapper>
                            <ProductQuantityWrapper>
                              <ProductName>Base Price: </ProductName>
                              <ProductQuantity>₹{Number(product?.price?.value)?.toFixed(2)} </ProductQuantity>
                            </ProductQuantityWrapper>
                            <ProductQuantityWrapper>
                              <ProductName>Quantity: </ProductName>
                              <ProductQuantity>{quantity?.count}</ProductQuantity>
                            </ProductQuantityWrapper>
                            <ProductQuantityWrapper>
                              <ProductName>Total Price: </ProductName>
                              <ProductQuantity>
                                {' '}
                                ₹{(Number(product?.price?.value) * Number(quantity?.count)).toFixed(2)}{' '}
                              </ProductQuantity>
                            </ProductQuantityWrapper>
                          </ProductDetailWrapper>
                        </ProductDetailSection>
                      </div>
                    </>
                  )
                })}
                <Wrapper>
                  {description?.images &&
                    description?.images?.map((image, index) => {
                      return (
                        <IssueImageWrapper key={index}>
                          <a href={image} rel="noreferrer" target="_blank">
                            <img
                              src={image ? image : ProductImage}
                              alt="issue-image"
                              onError={(e) => {
                                e.target.src =
                                  'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
                              }}
                            />
                          </a>
                        </IssueImageWrapper>
                      )
                    })}
                </Wrapper>

                <CardWrapper>
                  <CardDetailWrapper>
                    <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                      Expected response time
                    </Typography>
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      {moment(created_at)
                        .add(parseDuration(process.env.EXPECTED_RESPONSE_TIME ?? 'PT2H'), 'milliseconds')
                        .format('hh:mm a, MMMM Do, YYYY')}
                    </Typography>
                  </CardDetailWrapper>
                  <CardDetailWrapper>
                    <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                      Expected resolution time
                    </Typography>
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      {moment(created_at)
                        .add(parseDuration(process.env.EXPECTED_RESOLUTION_TIME ?? 'P1D'), 'milliseconds')
                        .format('hh:mm a, MMMM Do, YYYY')}
                    </Typography>
                  </CardDetailWrapper>
                  <CardDetailWrapper>
                    <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                      Order ID
                    </Typography>
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      {order_details?.id}
                    </Typography>
                  </CardDetailWrapper>
                  <CardDetailWrapper>
                    <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                      Issue ID
                    </Typography>
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      {/* {issueId} */}
                      {newIssueId}
                    </Typography>
                  </CardDetailWrapper>
                  <CardDetailWrapper>
                    <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                      Issue Raised On
                    </Typography>
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      {moment(created_at).format('MMMM Do, YYYY')}
                    </Typography>
                  </CardDetailWrapper>
                  <CardDetailWrapper>
                    <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                      Short Description
                    </Typography>
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      {description?.short_desc}
                    </Typography>
                  </CardDetailWrapper>
                  <CardDetailWrapper>
                    <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                      Long Description
                    </Typography>
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      {description?.long_desc}
                    </Typography>
                  </CardDetailWrapper>
                  <CardDetailWrapper>
                    <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                      Resolution TAT
                    </Typography>
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      24 hrs
                    </Typography>
                  </CardDetailWrapper>
                </CardWrapper>

                <Box component={'div'} className={classes.divider} />
                <ComplaintActionContainer>
                  {!issueActions?.some((x) => x.respondent_action === 'CLOSE') && (
                    <ActionButtonWrapper>
                      {isShowTakeAction() ? (
                        <Button
                          fullWidth
                          variant="contained"
                          // className={classes.helpButton}
                          disabled={statusLoading}
                          onClick={() => {
                            setToggleActionModal(true)
                          }}
                        >
                          {statusLoading ? <Loading /> : 'Take Action'}
                        </Button>
                      ) : (
                        ''
                      )}
                    </ActionButtonWrapper>
                  )}
                </ComplaintActionContainer>
              </div>
            </ComplaintSummaryCardWrapper>
          </Grid>
        </ComplaintIssueSection>
        {issueActions.some((x) => x.respondent_action === 'RESOLVED') && resolution && (
          <>
            <ResolutionDetailWrapper>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <ProductQuantityTitle>Resolution</ProductQuantityTitle>
              </Grid>
              <CardWrapper>
                <CardDetailWrapper>
                  <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                    Updated on
                  </Typography>
                  <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                    {moment(
                      (issueActions.reverse().find((obj) => obj.respondent_action === 'RESOLVED') || {}).updated_at,
                    ).format('MMMM Do, YYYY')}{' '}
                    at{' '}
                    {moment(
                      (issueActions.reverse().find((obj) => obj.respondent_action === 'RESOLVED') || {}).updated_at,
                    ).format('hh:mma')}
                  </Typography>
                </CardDetailWrapper>
                {/* {resolution_provider?.respondent_info?.organization?.person?.name && (
                  <CardDetailWrapper>
                    <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                      Updated by
                    </Typography>
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      {resolution_provider.respondent_info.organization.person.name}
                    </Typography>
                  </CardDetailWrapper>
                )} */}
                <CardDetailWrapper>
                  <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                    Short Description
                  </Typography>
                  {resolution?.short_desc && (
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      {resolution?.short_desc}
                    </Typography>
                  )}
                </CardDetailWrapper>
                <CardDetailWrapper>
                  <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                    Long Description
                  </Typography>
                  {resolution?.long_desc && (
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      {resolution?.long_desc}
                    </Typography>
                  )}
                </CardDetailWrapper>

                <CardDetailWrapper>
                  <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                    Action
                  </Typography>
                  <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                    {resolution?.action_triggered}
                  </Typography>
                </CardDetailWrapper>

                {resolution?.action_triggered === 'REFUND' && resolution?.refund_amount && (
                  <CardDetailWrapper>
                    <Typography component="div" variant="body" className={classes.customerDetailsLabel}>
                      Refund Amount
                    </Typography>
                    <Typography component="div" variant="body" className={classes.customerDetailsValue}>
                      ₹{resolution?.refund_amount}
                    </Typography>
                  </CardDetailWrapper>
                )}
              </CardWrapper>
            </ResolutionDetailWrapper>
          </>
        )}
      </ComplaintsSection>
    </ComplaintsDetailMainWrapper>
  )
}

export default ComplaintDetail
