export const CANCELATION_REASONS = [
  {
    key: '001',
    value: 'Price of one or more items has changed, and additional payment is required.',
    isApplicableForCancellation: false,
  },
  {
    key: '003',
    value: 'Product is available at a lower price elsewhere.',
    isApplicableForCancellation: false,
  },
  {
    key: '006',
    // value: 'Order not received as per buyer app TAT SLA',
    value: 'Order not received within the expected delivery time.',
    isApplicableForCancellation: false,
  },
  {
    key: '009',
    value: 'Wrong product was delivered.',
    isApplicableForCancellation: false,
  },
  {
    key: '010',
    value: 'Want to change the delivery address.',
    isApplicableForCancellation: false,
  },
]

export const RETURN_REASONS = [
  {
    key: '001',
    value: 'Buyer does not want product any more',
    isApplicableForNonReturnable: false,
  },
  {
    key: '002',
    value: 'Product available at lower than order price',
    isApplicableForNonReturnable: false,
  },
  {
    key: '003',
    value: 'Product damaged or not in usable state',
    isApplicableForNonReturnable: true,
  },
  {
    key: '004',
    value: 'Product is of incorrect quantity or size',
    isApplicableForNonReturnable: true,
  },
  {
    key: '005',
    value: 'Product delivered is different from what was shown and ordered',
    isApplicableForNonReturnable: true,
  },
]
