import React, { useContext, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AddCookie, getValueFromCookie } from 'utils/cookies'
import { search_types } from 'constants/searchTypes'
import { SearchContext } from 'context/searchContext'
import { useTheme } from 'context/newThemeContext'
import StoresImage from 'assets/images/no_image_found.png'
import { BrandItemContainer, StoresCard, StoresImageWrapper, BrandName } from 'components/category/brands/style'

const SingleBrand = ({ data, onMouseOver }) => {
  const { id, provider, domain, provider_descriptor } = data
  const { name: brand_name, images } = provider_descriptor
  const { setSearchData, setLocationData } = useContext(SearchContext)
  const history = useHistory()
  const locationData = useLocation()
  const useQuery = () => {
    const { search } = locationData
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  let query = useQuery()
  const [search, setSearch] = useState({
    type: search_types.PRODUCT,
    value: '',
  })
  const [searchedLocation, setSearchedLocation] = useState({
    name: '',
    lat: '',
    lng: '',
  })

  function getLastEnteredValues() {
    const searchProductName = query.get('s')
    let search_context = getValueFromCookie('search_context')
    if (search_context) {
      search_context = Object.assign({}, JSON.parse(search_context))
      setSearch(() => ({
        type: search_context.search.type,
        value: query.size > 0 && searchProductName ? searchProductName : '',
      }))
      setSearchedLocation(search_context.location)
      setSearchData(() => ({
        type: search_context.search.type,
        value: query.size > 0 && searchProductName ? searchProductName : '',
      }))
      setLocationData(() => search_context.location)
    }
  }

  useEffect(() => {
    getLastEnteredValues()
  }, [])

  const redirectBasedOnDomain = () => {
    let searchDataUpdate = Object.assign({}, JSON.parse(JSON.stringify(search)))
    const search_context = {
      search: searchDataUpdate,
      location: searchedLocation,
    }
    AddCookie('search_context', JSON.stringify(search_context))
    if (domain === 'ONDC:RET11') {
      history.push(`/brand?brandId=${provider}&outletId=${id}`)
    } else {
      history.push(`/brand?brandId=${provider}`)
    }
  }
  const { theme } = useTheme()

  return (
    <BrandItemContainer theme={theme} onMouseOver={onMouseOver} onClick={() => redirectBasedOnDomain()}>
      <StoresCard>
        <StoresImageWrapper>
          <img
            src={images?.length > 0 ? images[0] : StoresImage}
            alt={brand_name}
            onError={(e) => {
              e.target.src =
                'https://www.huber-online.com/daisy_website_files/_processed_/8/0/csm_no-image_d5c4ab1322.jpg'
            }}
          />
        </StoresImageWrapper>
      </StoresCard>
      <BrandName>{brand_name ? brand_name : ''}</BrandName>
    </BrandItemContainer>
  )
}

export default SingleBrand
