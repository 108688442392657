import * as React from 'react'
const SvgComponent = (props) => (
  <svg width={24} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.199951} width={24} height={24} rx={4} fill="#FF671F" />
    <path
      d="M7.7818 12.1814L12.2 16.5995L16.6181 12.1814C17.7272 11.0722 17.7272 9.27398 16.6181 8.16485C15.509 7.05573 13.7107 7.05573 12.6016 8.16485L12.2 8.5665L11.7983 8.16485C10.6892 7.05573 8.89092 7.05573 7.7818 8.16485C6.67267 9.27398 6.67267 11.0722 7.7818 12.1814Z"
      stroke="white"
      strokeWidth={0.875}
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
