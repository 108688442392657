import React, { useContext, useEffect, useState } from 'react'
import { toast_actions, toast_types } from '../../components/shared/toast/utils/toast'
import { ToastContext } from '../../context/toastContext'
import OtpInput from '../../components/otpInput'
import OtpBackIcon from '../../assets/svg/OtpBackIcon'
import CloseIcon from 'assets/svg/CloseIcon'
import Cookies from 'js-cookie'
import { Wrapper, OtpLogoContainer, LogoContainer, CrossIconWrapper, FormWrapper } from '../../styles/auth/Login'
import {
  LoginContainer,
  OtpLoginTextContainer,
  OtpLoginTitle,
  LoginSubTitle,
  LoginFormWrapper,
  OtpFormWrapper,
  OtpWrapper,
  OtpContainer,
  ForgotText,
  ResendText,
} from '../../styles/otpScreen'
import Logo from '../../assets/images/BharathamLogo.png'
import { Button as NewButton } from '@mui/material'
import usePost from 'hooks/usePost'
import Loading from 'components/shared/loading/loading'

const VerificationModal = ({ onClose, Data, signInType, imgLink }) => {
  const { mutateAsync } = usePost()
  const dispatch = useContext(ToastContext)
  const [otp, setOtp] = useState('')
  const [deviceName, setDeviceName] = useState('')
  const [deviceValue, setDeviceValue] = useState('')
  const [otpPage, setOtpPage] = useState(false)

  const [resetOtp, setResetOtp] = useState(false)
  const [loading, setLoading] = useState(false)
  const [inlineError, setInlineError] = useState({
    phone_error: '',
    otp_error: '',
  })

  // use this function to check the otp validation
  function checkOtp() {
    if (otp?.length !== 4) {
      setInlineError((inlineError) => ({
        ...inlineError,
        otp_error: 'Please enter a 4-digit OTP',
      }))
      return false
    } else {
      setInlineError((inlineError) => ({
        ...inlineError,
        otp_error: '',
      }))
    }

    return true
  }

  const resendOtp = async () => {
    setResetOtp(true)

    const payload = signInType === 'phone' ? { email: Data.email } : { phone: Data.phone }

    try {
      const data = await mutateAsync({
        url: '/clientApis/updateDataOtp',
        payload: payload,
      })

      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: data?.message,
        },
      })
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error.message,
        },
      })
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleVerifyOtp() // Trigger OTP login when Enter is pressed
    }
  }

  const getFullOtp = (otp) => {
    if (otp?.length === 4) setOtp(otp)
  }

  const handleUpdateOtp = async () => {
    const phoneNumber = Data?.phone.startsWith('+') ? Data.phone : `+${Data.phone}`
    const payload = signInType === 'phone' ? { email: Data.email } : { phone: phoneNumber }
    setLoading(true)
    try {
      const data = await mutateAsync({
        url: '/clientApis/updateDataOtp',
        payload: payload,
      })
      if (data?.success === true) {
        setOtpPage(true)
        setLoading(false)
      }

      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: data?.message,
        },
      })
    } catch (error) {
      setLoading(false)
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error.message,
        },
      })
    }
  }
  const handleVerifyOtp = async () => {
    checkOtp()
    const phoneNumber = Data?.phone.startsWith('+') ? Data.phone : `+${Data.phone}`
    setLoading(true)
    const payload = signInType === 'phone' ? { email: Data.email, otp: otp } : { phone: phoneNumber, otp: otp }
    if (otp?.length === 4) {
      try {
        const data = await mutateAsync({
          url: '/clientApis/verifyOtpToUpdate',
          payload: payload,
        })
        if (data?.success === true) {
          setOtpPage(true)
          setLoading(false)
          const phoneNumber = Data?.phone.startsWith('+') ? Data.phone : `+${Data.phone}`
          const payload = {
            userName: Data?.userName,
            phone: phoneNumber,
            email: Data?.email,
            userImage: Data.imageUrl ?? imgLink,
          }
          try {
            const res = await mutateAsync({
              url: '/clientApis/userProfile',
              payload: payload,
            })

            const userCookie = Cookies.get('user')
            if (userCookie) {
              const user = JSON.parse(userCookie)

              user.phone = res?.phone
              user.email = data?.email
              user.name = data?.userName

              const updatedUserCookie = JSON.stringify(user)

              Cookies.set('user', updatedUserCookie)
            }

            if (res?.message) {
              onClose()
              dispatch({
                type: toast_actions.ADD_TOAST,
                payload: {
                  id: Math.floor(Math.random() * 100),
                  type: toast_types.success,
                  message: 'User information updated successfully',
                },
              })
            }
          } catch (err) {
            dispatch({
              type: toast_actions.ADD_TOAST,
              payload: {
                id: Math.floor(Math.random() * 100),
                type: toast_types.error,
                message: err?.response?.data?.message,
              },
            })
          }
          //  }

          onClose()
          // dispatch({
          //   type: toast_actions.ADD_TOAST,
          //   payload: {
          //     id: Math.floor(Math.random() * 100),
          //     type: toast_types.success,
          //     message: data?.message,
          //   },
          // })
        }
      } catch (error) {
        setLoading(false)
        setResetOtp(true)
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: error?.response?.data?.message,
          },
        })
      }
    }
  }

  useEffect(() => {
    if (signInType === 'email') {
      setDeviceName('phone')
      setDeviceValue(Data?.phone)
    } else {
      setDeviceName('email')
      setDeviceValue(Data?.email)
    }
  }, [signInType])

  return (
    <Wrapper isOtpPage={otpPage}>
      {otpPage ? (
        <OtpLogoContainer>
          <div onClick={() => setOtpPage(false)}>
            <OtpBackIcon />
          </div>
          {/* <Link to="/"> */}
          <img src={Logo} alt="Bharatham" />
          {/* </Link> */}
        </OtpLogoContainer>
      ) : (
        <LogoContainer>
          {/* <Link to="/"> */}
          <img src={Logo} alt="Bharatham" />
          {/* </Link> */}
          <CrossIconWrapper>
            <CloseIcon onClick={onClose} />
          </CrossIconWrapper>
        </LogoContainer>
      )}

      {otpPage ? (
        <FormWrapper>
          <LoginContainer>
            <OtpLoginTextContainer>
              <OtpLoginTitle>Verification OTP</OtpLoginTitle>

              <LoginSubTitle>Please enter the OTP sent on {deviceValue}.</LoginSubTitle>
            </OtpLoginTextContainer>
            <LoginFormWrapper>
              <OtpFormWrapper>
                <OtpContainer>
                  <OtpWrapper>
                    <OtpInput
                      length={4}
                      getFullOtp={getFullOtp}
                      name={'otpp'}
                      resetOtp={resetOtp}
                      setResetOtp={setResetOtp}
                      onKeyDown={handleKeyDown}
                    />
                  </OtpWrapper>
                  <ForgotText></ForgotText>
                  {inlineError.otp_error && <span>{inlineError.otp_error}</span>}
                </OtpContainer>

                <div className="buttonSection">
                  <NewButton variant="contained" onClick={handleVerifyOtp}>
                    Submit
                  </NewButton>
                </div>
                <ResendText onClick={resendOtp}>
                  Didn&apos;t get the OTP? <span>Resend</span>
                </ResendText>
              </OtpFormWrapper>
            </LoginFormWrapper>
          </LoginContainer>
        </FormWrapper>
      ) : (
        <FormWrapper>
          <LoginContainer>
            <OtpLoginTextContainer>
              <OtpLoginTitle>Verification Required</OtpLoginTitle>
              <LoginSubTitle>For better security</LoginSubTitle>
              <LoginSubTitle>
                sending OTP on your {deviceName} : {deviceValue}
              </LoginSubTitle>
            </OtpLoginTextContainer>
            <LoginFormWrapper>
              <OtpFormWrapper>
                <div className="buttonSection">
                  {loading ? (
                    <Loading />
                  ) : (
                    <NewButton variant="contained" onClick={handleUpdateOtp}>
                      REQUEST OTP
                    </NewButton>
                  )}
                </div>
              </OtpFormWrapper>
            </LoginFormWrapper>
          </LoginContainer>
        </FormWrapper>
      )}
    </Wrapper>
  )
}

export default VerificationModal
