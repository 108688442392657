import React, { useContext, useState } from 'react'
import { Redirect } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword } from 'firebase/auth'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Input from '../../shared/input/input'
import PasswordInput from '../../shared/passwordInput/input'
import ErrorMessage from '../../shared/error-message/errorMessage'
import { toast_actions, toast_types } from '../../shared/toast/utils/toast'
import { getErrorMessage } from '../../../api/utils/mapFirebaseError'
import { AddCookie } from '../../../utils/cookies'
import { ToastContext } from '../../../context/toastContext'
import Google_Logo from '../../../assets/images/google.png'
import { isLoggedIn } from '../../../utils/validateToken'
import {
  PageWrapper,
  ImageContainer,
  LogoContainer,
  FormContainer,
  Title,
  FormWrapper,
  // Label,
  ForgotText,
  // OrText,
  SignUpText,
  AccountText,
  ButtonContainer,
  NavigationLinks,
  GoogleText,
  ButtonText,
} from '../../../styles/auth/Login'
import { v4 as uuidv4 } from 'uuid'
import Logo from '../../../assets/images/BharathamLogo.png'
import { Button as NewButton } from '@mui/material'
import Button from '../../shared/button/button'

const Login = () => {
  const auth = getAuth()
  const provider = new GoogleAuthProvider()
  const history = useHistory()
  const location = useLocation()
  const { state } = location

  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [signInUsingGoogleloading, setSignInUsingGoogleLoading] = useState(false)
  const [signInUsingEmailAndPasswordloading, setSignInUsingEmailAndPasswordLoading] = useState(false)
  const dispatch = useContext(ToastContext)
  const [inlineError, setInlineError] = useState({
    email_error: '',
    password_error: '',
  })
  // use this function to check the email
  function checkEmail() {
    if (!email) {
      setInlineError((inlineError) => ({
        ...inlineError,
        email_error: 'Email cannot be empty',
      }))
      return false
    }

    return true
  }

  function checkPassword() {
    if (!password) {
      setInlineError((inlineError) => ({
        ...inlineError,
        password_error: 'Password cannot be empty',
      }))

      return false
    } else if (password && password.length < 8) {
      setInlineError((inlineError) => ({
        ...inlineError,
        password_error: 'Password cannot be less than 8 characters',
      }))
      return false
    }

    return true
  }

  function handleLoginWithEmailAndPassword(e) {
    e.preventDefault()
    const allCheckPassed = [checkEmail(), checkPassword()].every(Boolean)
    if (!allCheckPassed) {
      return
    }

    setSignInUsingEmailAndPasswordLoading(true)
    signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        handleRedirect(result.user)
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = getErrorMessage(errorCode)
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: errorMessage,
          },
        })
      })
      .finally(() => setSignInUsingEmailAndPasswordLoading(false))
  }

  function handleLoginWithGoogle() {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Login with google',
    })
    setSignInUsingGoogleLoading(true)
    signInWithPopup(auth, provider)
      .then((result) => {
        handleRedirect(result.user)
      })
      .catch((error) => {
        if (error.code !== 'auth/popup-closed-by-user') {
          const errorMessage = error.message
          dispatch({
            type: toast_actions.ADD_TOAST,
            payload: {
              id: Math.floor(Math.random() * 100),
              type: toast_types.error,
              message: errorMessage,
            },
          })
        }
      })
      .finally(() => setSignInUsingGoogleLoading(false))
  }

  function handleRedirect(user) {
    const { displayName, email, photoURL, accessToken, uid } = user
    AddCookie('token', accessToken)
    localStorage.setItem('transaction_id', uuidv4())
    localStorage.setItem('token', accessToken)
    AddCookie('user', JSON.stringify({ name: displayName, id: uid, email, photoURL }))
    {
      state?.isCheckout ? history.push('/cart') : history.push('/')
    }
  }

  if (isLoggedIn()) {
    return <Redirect to={{ pathname: '/' }} />
  }

  return (
    <PageWrapper>
      <FormContainer>
        <LogoContainer>
          <Link to="/">
            <img src={Logo} alt="Bharatham" />
          </Link>
        </LogoContainer>
        <FormWrapper>
          <form onSubmit={handleLoginWithEmailAndPassword}>
            <Title>Let&apos;s Get Started</Title>
            <Input
              id="email"
              name="email"
              type="email"
              placeholder="Enter Email"
              label_name="Email"
              autoComplete="off"
              has_error={inlineError.email_error}
              onChange={(event) => {
                setEmail(event.target.value)
                setInlineError((inlineError) => ({
                  ...inlineError,
                  email_error: '',
                }))
              }}
              onBlur={checkEmail}
              required
            />
            {inlineError.email_error && <ErrorMessage>{inlineError.email_error}</ErrorMessage>}
            <PasswordInput
              id="password"
              name="password"
              placeholder="Enter Password"
              label_name="Password"
              autoComplete="off"
              has_error={inlineError.password_error}
              onChange={(event) => {
                setPassword(event.target.value)
                setInlineError((inlineError) => ({
                  ...inlineError,
                  password_error: '',
                }))
              }}
              onBlur={checkPassword}
              required
            />
            {inlineError.password_error && <ErrorMessage>{inlineError.password_error}</ErrorMessage>}
            <Link to="/forget-password">
              <ForgotText>Forgot Password?</ForgotText>
            </Link>
            <ButtonContainer>
              <NewButton className="login_button" variant="contained" type="submit">
                Login
              </NewButton>
              <Button
                className="google_button"
                isloading={signInUsingGoogleloading ? 1 : 0}
                disabled={signInUsingGoogleloading || signInUsingEmailAndPasswordloading}
                button_text={
                  <GoogleText style={{ display: 'flex', gap: '10px' }}>
                    <img src={Google_Logo} alt="logo" style={{ height: '20px' }} />
                    <ButtonText>Login with google</ButtonText>
                  </GoogleText>
                }
                onClick={handleLoginWithGoogle}
              />
            </ButtonContainer>
            <NavigationLinks>
              <AccountText>Don&apos;t have an account ?</AccountText>
              <Link to="/sign-up">
                <SignUpText>Sign Up</SignUpText>
              </Link>
            </NavigationLinks>
          </form>
        </FormWrapper>
      </FormContainer>
      <ImageContainer>
      </ImageContainer>
    </PageWrapper>
  )
}

export default Login
