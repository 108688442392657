import React, { useEffect, useState, useCallback, useContext } from 'react'
import ReactGA from 'react-ga4'
import Pagination from '@mui/material/Pagination'

import { toast_actions, toast_types } from '../../shared/toast/utils/toast'
import { ToastContext } from '../../../context/toastContext'
import useCancellablePromise from '../../../api/cancelRequest'
import useStyles from '../cart/styles'
import TicketCard from './ticketCard'
import { getSelectCall } from '../../../api/axios'
import Spinner from 'components/Loader'
import { ComplaintsMainWrapper, ComplaintsTitle, ComplaintsListWrapper } from 'components/orders/orderCard/style'
import NoDataFound from 'views/EmptyData'
import { NoDataWrapper } from 'styles/myOrder/orderDetails'
import { useParams } from 'react-router-dom'

export default function MyTickets() {
  // STATES
  const [tickets, setTickets] = useState([])
  const [fetchOrderLoading, setFetchOrderLoading] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalCount: 0,
    postPerPage: 10,
    state: 'activeIssues',
  })

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'Complaints' })
  }, [])

  // CONTEXT
  const dispatch = useContext(ToastContext)
  const classes = useStyles()
  const { issuesOrderId } = useParams()

  // HOOKS
  const { cancellablePromise } = useCancellablePromise()

  const getAllTickets = useCallback(async () => {
    setFetchOrderLoading(true)
    try {
      const { totalCount, issues } = await cancellablePromise(
        getSelectCall(
          `/issueApis/v1/getIssuesByOrderId?pageNumber=${pagination.currentPage}&limit=${pagination.postPerPage}&orderId=${issuesOrderId}`,
        ),
      )

      setPagination((prev) => ({
        ...prev,
        totalCount,
      }))
      setTickets(issues)
      setFetchOrderLoading(false)
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'You don’t seem to have an active Internet connection. Please check your connection & try again.',
        },
      })
      setFetchOrderLoading(false)
    }
  }, [pagination.currentPage, pagination.postPerPage])
  // const getAllTickets = useCallback(async () => {
  //   setFetchOrderLoading(true)
  //   try {
  //     const { totalCount, issues } = await cancellablePromise(
  //       getSelectCall(
  //         `/issueApis/v1/getIssues?pageNumber=${pagination.currentPage}&limit=${pagination.postPerPage}&state=${pagination.state}`,
  //       ),
  //     )

  //     setPagination((prev) => ({
  //       ...prev,
  //       totalCount,
  //     }))
  //     setTickets(issues)
  //     setFetchOrderLoading(false)
  //   } catch (err) {
  //     dispatch({
  //       type: toast_actions.ADD_TOAST,
  //       payload: {
  //         id: Math.floor(Math.random() * 100),
  //         type: toast_types.error,
  //         message: 'You don’t seem to have an active Internet connection. Please check your connection & try again.',
  //       },
  //     })
  //     setFetchOrderLoading(false)
  //   }
  // }, [pagination.currentPage, pagination.postPerPage])

  useEffect(() => {
    getAllTickets()
  }, [getAllTickets, pagination.currentPage])

  // empty state ui
  const empty_orders_state = <NoDataFound content={'No Data Found'} />

  return (
    <ComplaintsMainWrapper>
      <ComplaintsTitle>Complaints</ComplaintsTitle>
      {fetchOrderLoading ? (
        <div className={classes.loadingContainer}>
          <Spinner />
        </div>
      ) : (
        <ComplaintsListWrapper>
          {tickets?.length === 0 ? (
            <NoDataWrapper className="empty-state">{empty_orders_state}</NoDataWrapper>
          ) : (
            tickets?.map((order, orderIndex) => (
              <div key={`order-inx-${orderIndex}`}>
                <TicketCard data={order} orderDetails={order?.order_details} />
              </div>
            ))
          )}
          <div className="d-flex align-items-center justify-content-center" style={{ height: '60px', width: '100%' }}>
            {tickets?.length > 0 && pagination.totalCount > 10 && (
              <Pagination
                className={classes.pagination}
                count={Math.ceil(pagination.totalCount / pagination.postPerPage)}
                shape="rounded"
                color="primary"
                page={pagination.currentPage}
                onChange={(event, page) => {
                  event
                  setPagination((prev) => ({
                    ...prev,
                    currentPage: page,
                  }))
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
              />
            )}
          </div>
        </ComplaintsListWrapper>
      )}
    </ComplaintsMainWrapper>
  )
}
