import { useState, useEffect, useMemo } from 'react'
import useGet from 'hooks/useGet'
import ComplaintsCard from 'views/Complaints/ComplaintsCard'
import { Container, PaginationWrapper } from 'styles/ComplaintsCard'
import NoDataFound from 'views/EmptyData'
import { Pagination } from '@mui/material'
// import Spinner from 'components/Loader'

const ActiveComplaints = ({ orderId }) => {
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalCount: 0,
    postPerPage: 10,
    status: 'activeIssues',
  })


  const [totalOrdersCount, setTotalOrdersCount] = useState(0)
  // const [loading, setLoading] = useState(true)

  // Memoize apiUrl to prevent unnecessary re-renders
  const apiUrl = useMemo(() => {
    let url = `/issueApis/v1/getIssues?pageNumber=${pagination.currentPage}&limit=${pagination.postPerPage}&state=${pagination.status}`
    if (orderId) {
      setPagination((prev) => ({ ...prev, currentPage: 1 }))
      url += `&search=${orderId}`
    }

    return url
  }, [pagination.currentPage, pagination.postPerPage, pagination.status, orderId])

  const { data: getIssues, refetch } = useGet('get-issues', apiUrl)

  // Update totalCount only when it changes to prevent unnecessary re-renders
  // useEffect(() => {
  //   if (getIssues?.totalCount !== pagination.totalCount) {
  //     setPagination((prev) => ({
  //       ...prev,
  //       totalCount: getIssues?.totalCount || 0,
  //     }))
  //   }

  // }, [getIssues?.totalCount, pagination.totalCount])

  const issueList = getIssues?.issues

  // Refetch data when orderId changes
  useEffect(() => {
    refetch()
  }, [orderId, refetch, pagination.currentPage])

  useEffect(() => {
    if (getIssues) {
      setPagination((prev) => ({
        ...prev,
        totalOrder: getIssues.totalOrder || 0,
      }))
      setTotalOrdersCount(getIssues.totalOrder)
    }
  }, [getIssues])

  return (
    // <Container>
    //   {issueList?.length > 0 ? (
    //     issueList?.map((issues, index) => (
    //       <div key={index}>
    //         <ComplaintsCard issueDetails={issues} />
    //       </div>
    //     ))
    //   ) : (
    //     <NoDataFound content={'No Complaint Found'} />
    //   )}
    // </Container>

    <Container>
      {/* {loading ? (
        <Spinner />
      ) : ( */}
      <>
        {issueList?.length > 0 ? (
          issueList?.map((issues, index) => (
            <div key={index}>
              <ComplaintsCard issueDetails={issues} />
            </div>
          ))
        ) : (
          <NoDataFound content={'No Complaint Found'} />
        )}
        <PaginationWrapper>
          <Pagination
            count={Math.ceil(totalOrdersCount / pagination.postPerPage)}
            shape="rounded"
            color="primary"
            page={pagination.currentPage}
            onChange={(event, currentPage) => {
              event
              setPagination((prevState) => ({ ...prevState, currentPage }))
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }}
          />
        </PaginationWrapper>
      </>
      {/* // )} */}
    </Container>
  )
}

export default ActiveComplaints
