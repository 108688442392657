import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useStyles from './style'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { ReactComponent as CheckedIcon } from '../../../assets/images/checked.svg'
import { payment_methods } from '../../../constants/payment-methods'
import { constructQouteObject } from '../../../api/utils/constructRequestObject'
import { AddCookie, getValueFromCookie } from '../../../utils/cookies'
import { getSelectCall, postCheckoutCall } from '../../../api/axios'
import useCancellablePromise from '../../../api/cancelRequest'
import { SSE_TIMEOUT } from '../../../constants/sse-waiting-time'
import { ToastContext } from '../../../context/toastContext'
import { toast_actions, toast_types } from '../../shared/toast/utils/toast'
import { getOrCreateDeviceId } from 'helper'

const StepPaymentContent = (props) => {
  const {
    activePaymentMethod,
    setActivePaymentMethod,
    updateInitLoading,
    cartItemsData,
    updatedCartItemsData,
    setUpdatedCartItems,
    responseReceivedIds,
    selectedFulfillments,
    setCheckedState,
    checkedState,
    // fulfillments,
    initOrder,
    // setInitOrder,
  } = props
  const classes = useStyles()

  // const getTransactionId = new Map(JSON.parse(getValueFromCookie('orders-cart-info')))
  const history = useHistory()

  const [initializeOrderLoading, setInitializeOrderLoading] = useState(false)
  const [cartItems, setCartItems] = useState([])
  const updatedCartItems = useRef([])
  const responseRef = useRef([])
  const eventTimeOutRef = useRef([])

  const dispatch = useContext(ToastContext)

  // use this function to dispatch error
  function dispatchToast(type, message) {
    dispatch({
      type: toast_actions.ADD_TOAST,
      payload: {
        id: Math.floor(Math.random() * 100),
        type,
        message,
      },
    })
  }

  // HOOKS
  const { cancellablePromise } = useCancellablePromise()

  const transactionData = JSON.parse(localStorage.getItem('transactionData'))
  useEffect(() => {
    if (cartItemsData) {
      setCartItems(cartItemsData)
    }

    if (updatedCartItemsData) {
      updatedCartItems.current = updatedCartItemsData
    }
  }, [cartItemsData, updatedCartItemsData])

  const handleSuccess = () => {
    setInitializeOrderLoading(false)
    updateInitLoading(false)
    let checkoutObj = {
      successOrderIds: [],
      productQuotes: [],
    }

    responseRef.current.forEach((item) => {
      const { message, context } = item
      checkoutObj = {
        productQuotes: [
          ...checkoutObj.productQuotes,
          { quote: message?.order?.quote, transaction_id: context?.transaction_id },
        ],
        successOrderIds: checkoutObj.successOrderIds.concat(message?.order?.items.map((item) => item.id.toString())),
      }
    })
    // AddCookie("checkout_details", JSON.stringify(checkoutObj));
    localStorage.setItem('checkout_details', JSON.stringify(checkoutObj))
    // handleNext();

    // setInitOrder(false)
  }

  const onInitializeOrder = async (message_id, itemLength) => {
    setInitializeOrderLoading(true)
    try {
      localStorage.setItem('selectedItems', JSON.stringify(updatedCartItems))
      const data = await cancellablePromise(
        getSelectCall(`/clientApis/v2/on_initialize_order?messageIds=${message_id}`),
      )

      responseRef.current = [...responseRef.current, data[0]]
      const Data = responseRef.current
      const newArray = Data.map((obj) => {
        if (obj.message && obj.message.order) {
          return {
            ...obj,
            message: {
              quote: {
                ...obj.message.order,
              },
            },
          }
        }

        return obj
      })

      setUpdatedCartItems(newArray)

      let oldData = updatedCartItems.current

      oldData.forEach((ele) => {
        ele.message.quote.quote = data[0].message.order.quote
        ele.message.quote.payment = data[0].message.order.payment
      })

      if (itemLength === responseRef.current.length) {
        // setUpdatedCartItems(oldData)
        handleSuccess()
        setActivePaymentMethod(payment_methods.JUSPAY)
      }

      // setUpdatedCartItems(oldData)
      // handleSuccess()
      // setActivePaymentMethod(payment_methods.JUSPAY)
      //   } else {
      //     //Seller return mismatch items of current order
      //     dispatchToast(
      //       toast_types.error,
      //       'Oops! Some items went out of stock & requested you to update the cart before the purchase.',
      //     )
      //     setInitializeOrderLoading(false)
      //     updateInitLoading(false)
      //   }
      // } else {
      //   //Array have different length
      //   dispatchToast(
      //     toast_types.error,
      //     'Oops! Some items went out of stock & requested you to update the cart before the purchase.',
      //   )
      //   setInitializeOrderLoading(false)
      //   updateInitLoading(false)
      // }
    } catch (err) {
      dispatchToast(toast_types.error, err?.response?.data?.error?.message)
      setInitializeOrderLoading(false)
      updateInitLoading(false)
    }
  }

  // use this function to initialize the order
  const onInit = async (message_id, itemLength) => {
    setInitializeOrderLoading(true)
    eventTimeOutRef.current = []

    const token = getValueFromCookie('token')
    let header = {
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
      },
    }

    if (message_id) {
      message_id.forEach((id) => {
        try {
          let es = new window.EventSourcePolyfill(
            `${process.env.REACT_APP_BASE_URL}clientApis/events/v2?messageId=${id}`,
            header,
          )

          es.addEventListener('on_init', (e) => {
            try {
              const { messageId } = JSON.parse(e.data)
              onInitializeOrder(messageId, itemLength)

              // Close the event connection as soon as the response is received
              es.close()
              const eventTimeout = eventTimeOutRef.current.find((item) => item.eventSource === es)
              if (eventTimeout) {
                clearTimeout(eventTimeout.timer)
                eventTimeOutRef.current = eventTimeOutRef.current.filter((item) => item.eventSource !== es)
              }
            } catch (parseError) {
              setCheckedState(false)
              setInitializeOrderLoading(false)
            }
          })

          es.onerror = (error) => {
            error
            es.close()
          }

          const timer = setTimeout(() => {
            eventTimeOutRef.current.forEach(({ eventSource, timer }) => {
              eventSource.close()
              clearTimeout(timer)
            })

            if (responseRef.current.length < itemLength) {
              setInitializeOrderLoading(false)
              dispatch({
                type: toast_actions.ADD_TOAST,
                payload: {
                  id: Math.floor(Math.random() * 100),
                  type: toast_types.warning,
                  message:
                    'The seller is not reachable at the moment. Please try again later. Thank you for your patience.',
                },
              })

              history.push('/')
              return
            }
          }, SSE_TIMEOUT)

          eventTimeOutRef.current = [
            ...eventTimeOutRef.current,
            {
              eventSource: es,
              timer,
            },
          ]
        } catch (error) {
          setInitializeOrderLoading(false)
          dispatch({
            type: toast_actions.ADD_TOAST,
            payload: {
              id: Math.floor(Math.random() * 100),
              type: toast_types.error,
              message: error.message,
            },
          })
        }
      })
    }
  }

  const removeNullValues = (object) => {
    Object.entries(object).forEach(([k, v]) => {
      if (v && typeof v === 'object') removeNullValues(v)
      if ((v && typeof v === 'object' && !Object.keys(v).length) || v === null || v === undefined || v.length === 0) {
        if (Array.isArray(object)) object.splice(k, 1)
        else if (!(v instanceof Date)) delete object[k]
      }
    })
    return object
  }
  const initializeOrder = async (itemsList) => {
    // const initializeOrder = async (itemsList, deliveryObject) => {
    try {
      const deviceId = await getOrCreateDeviceId()
      const shippingAddress = JSON.parse(getValueFromCookie('delivery_address'))
      const clonedItemsList = JSON.parse(JSON.stringify(itemsList))

     

      responseRef.current = []
      setInitializeOrderLoading(true)

      const itemsPayload = await Promise.all(
        clonedItemsList.map(async (itemGroup) => {
          const itemsData = await Promise.all(
            itemGroup.map(async (item) => {
              item.fulfillment_id = selectedFulfillments[item.local_id]
              // const parentItemId = updatedCartItems.current?.find((data) => data.id === item.local_id)?.parent_item_id
              return {
                // ...item,

                id: item?.id,
                local_id: item?.local_id,
                tags: item?.tags,
                fulfillment_id: item?.fulfillment_id,
                quantity: item?.quantity,
                provider: {
                  id: item?.provider?.id,
                  local_id: item?.provider?.local_id,
                  locations: item?.provider?.locations.map((location) => ({
                    id: location.id,
                    local_id: location.local_id,
                  })),
                },
                customisations: item?.customisations,
                hasCustomisations: item?.hasCustomisations,
                userId: item?.userId,
              }
            }),
          )

          const transactionIds = transactionData
            ?.filter((i) =>
              i?.productId?.some((productId) => itemGroup.some((item) => item?.product?.id === productId)),
            )
            .map((i) => i?.transaction_id)
          // const matchedDeliveries = deliveryObject.filter((deliveryItem) =>
          //   itemsData.some((item) => item?.product?.id === deliveryItem?.ProductId),
          // )

          // const fulfillments = matchedDeliveries.map((deliveryItem) => ({
          //   id: deliveryItem.fulfillment.id,
          //   type: deliveryItem.fulfillment.type,
          //   '@ondc/org/provider_name': deliveryItem.fulfillment['@ondc/org/provider_name'],
          //   tracking: deliveryItem.fulfillment.tracking,
          //   '@ondc/org/category': deliveryItem.fulfillment['@ondc/org/category'],
          //   '@ondc/org/TAT': deliveryItem.fulfillment['@ondc/org/TAT'],
          //   state: {
          //     descriptor: {
          //       code: deliveryItem.fulfillment.state.descriptor.code,
          //     },
          //   },
          // }))

          return {
            context: {
              transaction_id: transactionIds?.[0],
              city: shippingAddress?.address.areaCode,
              // state: shippingAddress?.address.state,
              domain: itemGroup[0].domain,
              // pincode: shippingAddress?.address.areaCode,
            },
            message: {
              items: itemsData,
              // fulfillments: fulfillments,
              billing_info: {
                address: removeNullValues(shippingAddress?.address),
                phone: shippingAddress?.descriptor?.phone,
                name: shippingAddress?.address?.name,
                email: shippingAddress?.descriptor?.email,
              },
              delivery_info: {
                type: 'Delivery',
                phone: shippingAddress?.descriptor?.phone,
                name: shippingAddress?.address?.name,
                email: shippingAddress?.descriptor?.email,
                location: {
                  gps: shippingAddress?.gps,
                  address: shippingAddress?.address,
                },
              },
              payment: {
                type: activePaymentMethod === payment_methods.COD ? 'ON-FULFILLMENT' : 'ON-ORDER',
              },
            },
            deviceId: deviceId,
          }
        }),
      )

      const data = await cancellablePromise(postCheckoutCall('/clientApis/v2/initialize_order', itemsPayload))

      // Handle NACK errors
      const isNACK = data?.some((item) => item?.error && item?.message?.ack?.status === 'NACK')
      if (isNACK) {
        setCheckedState(false)
        dispatchToast(toast_types.error, isNACK.error.message)
        setInitializeOrderLoading(false)
        updateInitLoading(false)
        return
      }

      const parentTransactionIdMap = new Map()
      data?.forEach((data) => {
        const provider_id = data?.context?.provider_id
        parentTransactionIdMap.set(
          provider_id,
          {
            parent_order_id: data?.context?.parent_order_id,
            transaction_id: data?.context?.transaction_id,
          },
        )
      })

      // Store parent order id and map to cookies
      AddCookie('parent_order_id', data[0]?.context?.parent_order_id)
      AddCookie('parent_and_transaction_id_map', JSON.stringify(Array.from(parentTransactionIdMap.entries())))

      const itemData = data.map((txn) => txn.context?.message_id)
      onInit(itemData, itemsList.length)
    } catch (err) {
      setCheckedState(false)
      dispatchToast(toast_types.error, err?.response?.data[0]?.message)
      setInitializeOrderLoading(false)
      updateInitLoading(false)
    }
  }

  const handleInitializaOrder = () => {
    setInitializeOrderLoading(true)
    updateInitLoading(true)
    let c = cartItems?.map((item) => {
      return item?.item
    })

    const request_object = constructQouteObject(
      c?.filter(({ product }) => responseReceivedIds.includes(product?.id?.toString())),
    )
    //need to test
    // const request_object = constructQouteObject(
    //   c?.filter(({ provider }) => responseReceivedIds.includes(provider?.local_id?.toString())),
    // )

    // initializeOrder(request_object, deliveryObject)
    initializeOrder(request_object)
  }

  useEffect(() => {
    if (initOrder && checkedState) {
      handleInitializaOrder()
    }
  }, [initOrder, checkedState])

  return (
    <Grid container spacing={3} style={{ display: 'none' }}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Card
          className={`${classes.paymentCard} ${activePaymentMethod === payment_methods.COD ? classes.activeCard : ''} ${
            initializeOrderLoading ? classes.nonClickable : ''
          }`}
          onClick={() => {
            if (!initializeOrderLoading && activePaymentMethod !== payment_methods.COD) {
              setActivePaymentMethod(payment_methods.COD)
              handleInitializaOrder()
            }
          }}
        >
          {activePaymentMethod === payment_methods.COD && <CheckedIcon className={classes.checkedIcon} />}
        </Card>
        <Typography className={classes.paymentTypo} variant="body" component="div">
          Cash on delivery
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Card
          className={`${classes.paymentCard} ${
            activePaymentMethod === payment_methods.RAZORPAY ? classes.activeCard : ''
          } ${initializeOrderLoading ? classes.nonClickable : ''}`}
          onClick={async () => {
            if (!initializeOrderLoading && activePaymentMethod !== payment_methods.RAZORPAY) {
              setActivePaymentMethod(payment_methods.RAZORPAY)
              handleInitializaOrder()
            }
          }}
        >
          {activePaymentMethod === payment_methods.RAZORPAY && <CheckedIcon className={classes.checkedIcon} />}
        </Card>
        <Typography className={classes.paymentTypo} variant="body" component="div">
          Prepaid
        </Typography>
      </Grid>
    </Grid>
  )
}

export default StepPaymentContent
