import React from 'react'
import styles from './styles/globalStyles.module.scss'
import './index.css'
import OndcRoutes from './router'
import './api/firebase-init'
import { ThemeProvider, useThemeContext } from './context/themeContext'
import { NewThemeProvider } from './context/newThemeContext'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Meta from 'components/meta'

function App() {
  return (
    <ThemeProvider>
      <AppContent />
    </ThemeProvider>
  )
}

function AppContent() {
  const { mode } = useThemeContext()

  return (
    <NewThemeProvider>
      <div className={`${styles.background} ${mode}`}>
        <Meta />
        <OndcRoutes />
      </div>
    </NewThemeProvider>
  )
}

export default App
