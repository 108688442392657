import React from 'react'
import { Helmet } from 'react-helmet-async'

const ProductMetadata = ({ productDetails }) => {
  const title = productDetails?.item_details?.descriptor?.name
  const description = productDetails?.item_details?.descriptor?.short_desc
  const formattedTitle = `${title} | Bharatham`
  return (
    <Helmet>
      <title>{formattedTitle}</title>
      <meta name="title" content={formattedTitle} />
      <meta name="description" content={description} />
      <meta property="og:title" content={formattedTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={window.location.href} />
    </Helmet>
  )
}

export default ProductMetadata
