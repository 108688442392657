import React, { useState, useEffect, useContext } from 'react'
import ReactGA from 'react-ga4'
import Tab from '../../components/Tab'
import { Container, MainContainer, SearchContent, SearchWrapper, Title } from '../../styles/myOrder'
import ActiveComplaints from 'views/Complaints/ActiveComplaints'
import ClosedComplaints from 'views/Complaints/ClosedComplaints'
import { ReactComponent as SearchIcon } from 'assets/images/search.svg'
import { IconButton, InputBase } from '@mui/material'
import useCancellablePromise from 'api/cancelRequest'
import { getSelectCall } from 'api/axios'
import { toast_actions, toast_types } from 'components/shared/toast/utils/toast'
import { ToastContext } from 'context/toastContext'
const Complaints = () => {
  const [value, setValue] = useState(0)
  const [orderId, setOrderId] = useState('')
  const { cancellablePromise } = useCancellablePromise()
  const dispatch = useContext(ToastContext)
  const handleChange = (event, newValue) => {
    event
    setValue(newValue)
  }
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalCount: 0,
    postPerPage: 10,
    state: 'activeIssues',
  })

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search, title: 'My Orders' })
  }, [])

  const items = [
    {
      label: 'Active',
      value: 0,
    },
    {
      label: 'Closed',
      value: 1,
    },
  ]
  //Dynamic panel items data
  const panelItems = [
    {
      id: 0,
      data: <ActiveComplaints orderId={orderId} pagination={pagination} />,
    },
    {
      id: 1,
      data: <ClosedComplaints orderId={orderId} pagination={pagination} />,
    },
  ]

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleComplaintsSearch()
    }
  }

  const handleComplaintsSearch = async () => {
    try {
      const { totalCount } = cancellablePromise(
        getSelectCall(
          `/issueApis/v1/getIssues?pageNumber=${pagination.currentPage}&limit=${pagination.postPerPage}&state=${pagination.state}&search=${orderId}`,
        ),
      )

      setPagination((prev) => ({
        ...prev,
        totalCount,
      }))
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: 'You don’t seem to have an active Internet connection. Please check your connection & try again.',
        },
      })
    }
  }

  return (
    <MainContainer>
      <Container>
        <Title>Complaints</Title>
        <SearchWrapper>
          <SearchContent>
            <IconButton
              type="button"
              aria-label="search"
              onClick={(event) => {
                event.stopPropagation()
                // handleIssueSearch()
              }}
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              fullWidth
              // className={classes.inputBase}
              placeholder="Search by Order ID"
              inputProps={{ 'aria-label': 'Search by Order ID' }}
              name="search"
              value={orderId || ''}
              onKeyDown={handleKeyDown}
              onChange={(e) => setOrderId(e.target.value)}
            />
          </SearchContent>
        </SearchWrapper>
      </Container>

      <Tab value={value} handleChange={handleChange} items={items} panelItems={panelItems} />
    </MainContainer>
  )
}

export default Complaints
