const metaData = {
  '/': {
    title: 'Bharatham - Shop Directly from Manufacturers | Electronics, Fashion & More',
    description:
      'Shop a wide range of products on Bharatham, from electronics to fashion, all directly from manufacturers. Enjoy unbeatable prices, fast delivery, and a seamless shopping experience.',
  },
  '/shipping-policy': {
    title: 'Shipping Policy - Bharatham | Fast & Reliable Delivery',
    description:
      'Learn about Bharatham’s shipping policy, including delivery timelines, shipping charges, and coverage areas. We ensure fast and reliable delivery for a seamless shopping experience.',
  },
  '/cancellation-policy': {
    title: 'Cancellation Policy - Bharatham | Easy Returns & Refunds',
    description:
      'Understand Bharatham’s cancellation and refund policy. Find out how to cancel your orders, request refunds, and ensure a smooth shopping experience with our hassle-free process.',
  },
  '/about-us': {
    title: 'Bharatham | Your Trusted Online Marketplace',
    description:
      'Discover the story behind Bharatham, a trusted online marketplace offering quality products directly from manufacturers. Learn about our mission, values, and commitment to customer satisfaction.',
  },
  '/contact-us': {
    title: 'Contact Us - Bharatham | Get in Touch with Our Support Team',
    description:
      "Have questions? Contact Bharatham's customer support for assistance. Whether you need help with your order or have general inquiries, we're here to assist you with fast and friendly service.",
  },
  '/terms-conditions': {
    title: 'Terms & Conditions - Bharatham | Shopping Policies & User Rights',
    description:
      'Read the Terms & Conditions for using Bharatham. Understand our policies, your rights, and responsibilities to ensure a secure and trustworthy shopping experience on our platform.',
  },
  '/faq': {
    title: 'Frequently Asked Questions - Bharatham | Your Trusted Marketplace',
    description:
      'Find answers to your questions about shopping on Bharatham. Learn about orders, shipping, returns, payments, and more to enhance your online shopping experience.',
  },
  '/returns-refund': {
    title: 'Returns & Refund Policy - Bharatham | Easy Returns & Hassle-Free Refunds',
    description:
      "Learn about Bharatham's returns and refund policy. Understand how to return products and get hassle-free refunds for a smooth shopping experience.",
  },
  '/cart': {
    title: 'Your Shopping Cart | Bharatham',
    description:
      'View and manage the items in your shopping cart. Check out securely with high-quality products at unbeatable prices from Bharatham.',
  },
  '/checkout': {
    title: 'Secure Checkout | Bharatham',
    description:
      'Complete your purchase with our secure and hassle-free checkout process. Shop high-quality products at unbeatable prices from Bharatham.',
  },
  '/products': {
    title: 'Electronics - Shop Mobiles, Laptops & More at Bharatham',
    description:
      'Explore a wide range of electronics at Bharatham. Shop mobiles, laptops, gadgets, and more directly from manufacturers at the best prices with fast delivery options.',
  },
}
export default metaData
