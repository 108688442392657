import styled from 'styled-components'
import { theme } from 'styles/Theme/theme'

export const RaiseIssueModalWrapper = styled.div`
  max-width: 700px;
  min-width: 700px;
  width: 100%;
  overflow: visible;
  @media (max-width: 450px) {
    max-width: 300px;
    min-width: 300px;
  }
`

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid ${theme.SECONDARYBORDERCOLOR};
  ::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: ${theme.PRIMARYGREYCOLOR};
  }
  .file-input {
    cursor: pointer;
  }
  .upload-image {
    background: ${theme.TERTIARYBACKGROUNDCOLOR};
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    color: ${theme.PRIMARYGREYCOLOR};
    text-transform: none;
    &:disabled {
      cursor: not-allowed;
    }
  }
`
export const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: ${theme.PRIMARYGREYCOLOR};
  padding: 10px 0;
  span {
    color: red;
  }
`
export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 250px;
  overflow: auto;
  @media (max-width: 450px) {
    max-height: unset;
    overflow: unset;
  }
`
export const IssueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`
export const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 500px;
  width: 100%;
  gap: 10px;
  button {
    width: unset;
  }
`
export const ProductImageWrapper = styled.div`
  img {
    width: 130px;
    height: 70px;
    object-fit: contain;
  }
  @media (max-width: 450px) {
    img {
      width: 94px;
    }
  }
`
export const ProductDetailWrapper = styled.div`
  max-width: 200px;
  width: 100%;
`

export const ProductNameWrapper = styled.div`
  font-size: 16px;
  color: ${theme.PRIMARYGREYCOLOR};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const QuantityWrapper = styled.div`
  width: 90px;
  height: 30px;
  background: ${theme.PRIMARYBORDERCOLORLIGHT};
  border: 1px solid ${theme.DARKBLUE};
  color: ${theme.DARKBLUE};
  border-radius: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media (max-width: 450px) {
    width: 180px;
  }
`
export const ProductQuantityWrapper = styled.div`
  font-size: 14px;
  color: ${theme.PRIMARYTEXTCOLORDARK};
`
export const ProductPriceWrapper = styled.div`
  display: flex;
`
export const ProductPrice = styled.div`
  font-size: 16px;
  color: ${theme.PRIMARYGREYCOLOR};
  white-space: nowrap;
  @media (max-width: 450px) {
    display: none;
  }
`
export const MobileProductPrice = styled.div`
  display: none;
  @media (max-width: 450px) {
    display: block;
    font-size: 16px;
    color: ${theme.PRIMARYGREYCOLOR};
    white-space: nowrap;
  }
`
export const IssueReasonWrapper = styled.div`
  .dropdown-menu.issue-dropdown {
    width: 100%;
    max-height: 250px;
    overflow: auto;
    margin: 250px 0 !important;
    @media (max-width: 767px) {
      margin: 378px 0 !important;
      max-height: 100px;
    }
  }
  .reason-wrapper {
    height: 36px !important;
    font-size: 14px !important;
    color: ${theme.PRIMARYGREYCOLOR} !important;
  }
`
export const ReasonTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: ${theme.PRIMARYGREYCOLOR};
  margin: 5px;
`
export const SelectReasonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px 15px;
  height: 37px;
  background-color: ${theme.TERTIARYBACKGROUNDCOLOR};
  /* border: 1px solid ${theme.INPUTCOLOR}; */
  border-radius: 8px;
  margin-top: 4px;
  cursor: pointer;
`
export const PlaceholderText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: ${theme.PRIMARYGREYCOLOR};
  margin: 0;
`
export const SupportedImageFormats = styled.div`
  color: ${theme.GREYCOLOR};
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
`
export const IssueImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  gap: 5px;
  img {
    width: 100px;
    height: 80px;
    object-fit: contain;
  }
  @media (max-width: 767px) {
    gap: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
  }
`
export const UploadProductImageWrapper = styled.div`
  display: flex;

  img {
    width: 140px;
  }
  svg {
    cursor: pointer;
    background: ${theme.CROSSICONCOLOR};
    margin-top: -10px;
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px 0 0 16px;
  width: 100%;
`
export const MinusWrapper = styled.div`
  height: 100%;
  width: 100%;
`
export const PlusWrapper = styled.div`
  height: 100%;
  width: 100%;
`
