import React from 'react'
import useStyles from './style'
import { useHistory, useLocation } from 'react-router-dom'
import { categoryList as Categories } from '../../../constants/categories'
import { useTheme } from '../../../context/newThemeContext'
import { CategoryContainer, CategoriesContainer } from '../subCategories/style'

const SingleCategory = ({ data }) => {
  const history = useHistory()
  const classes = useStyles()
  const locationData = useLocation()
  const useQuery = () => {
    const { search } = locationData
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  let query = useQuery()
  const categoryName = query.get('c')
  const updateSearchParams = () => {
    const params = new URLSearchParams({ ['c']: `${data.routeName}` })
    history.replace({ pathname: locationData.pathname, search: params.toString() })
  }
  const { theme } = useTheme()
  return (
    <CategoryContainer theme={theme} onClick={() => updateSearchParams()}>
      <div
        className={`${classes.categoryItemImageContainer} ${categoryName === data.routeName ? classes.selectedCategory : ''}`}
      >
        <img className={classes.categoryImage} src={data.imageUrl} alt={data.name} />
      </div>
      <h1 className={classes.categoryNameTypo}>{data.name}</h1>
    </CategoryContainer>
  )
}

const CategoriesComponent = () => {
  return (
    <CategoriesContainer>
      {Categories?.map((category, index) => (
        <SingleCategory key={`single-category-${index}`} data={category} index={index} />
      ))}
    </CategoriesContainer>
  )
}

export default CategoriesComponent
