import React, { useContext, useEffect, useState } from 'react'
import useStyles from './style'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import MuiLink from '@mui/material/Link'
import Loading from '../../shared/loading/loading'
import { Link } from 'react-router-dom'
import SingleOutlet from './singleOutlet'
import { getAllOutletsRequest } from '../../../api/brand.api'
import useCancellablePromise from '../../../api/cancelRequest'
import no_image_found from '../../../assets/images/no_image_found.png'
import { SearchContext } from '../../../context/searchContext'
import { LocationContext } from '../../../context/locationContext'
import { ToastContext } from '../../../context/toastContext'
import { toast_actions, toast_types } from '../../shared/toast/utils/toast'
import { BreadcrumbWrapper } from '../products/style'

const Outlets = ({ brandDetails, brandId }) => {
  const classes = useStyles()
  const { locationData: deliveryAddressLocation } = useContext(SearchContext)
  const { latitude, longitude } = useContext(LocationContext)

  const { descriptor } = brandDetails
  const { name: brandName, symbol } = descriptor
  const dispatch = useContext(ToastContext)
  const [isLoading, setIsLoading] = useState(false)
  const [outlets, setOutlets] = useState([])

  // HOOKS
  const { cancellablePromise } = useCancellablePromise()

  const getAllOutlets = async () => {
    setIsLoading(true)
    try {
      const reqParams = {
        lat: latitude,
        lng: longitude,
      }
      const data = await cancellablePromise(getAllOutletsRequest(brandId, reqParams))

      setOutlets(data.data)
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getAllOutlets()
  }, [brandId, deliveryAddressLocation])

  return (
    <Grid container spacing={4} className={classes.outletsContainer}>
     
      <BreadcrumbWrapper>
        <Breadcrumbs aria-label="breadcrumb">
          <MuiLink component={Link} underline="hover" color="inherit" to="/">
            <Typography className="text-color">Home</Typography>
          </MuiLink>
          <MuiLink component={Link} underline="hover" color="inherit" to="/providers">
            <Typography className="text-color">Providers</Typography>
          </MuiLink>
          {brandId && <Typography color="text.primary">{brandName}</Typography>}
        </Breadcrumbs>
      </BreadcrumbWrapper>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="h4" color={'success'}>
          All Outlets near you
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid container spacing={2}>
          {isLoading ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Loading />
            </Grid>
          ) : (
            <>
              {outlets?.length > 0 ? (
                <>
                  {outlets.map((outlet, ind) => (
                    <Grid key={`outlet-item-${ind}`} item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <SingleOutlet
                        brandId={brandId}
                        outletDetails={outlet}
                        brandImageUrl={symbol ? symbol : no_image_found}
                      />
                    </Grid>
                  ))}
                </>
              ) : (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="body1">No Outlets available</Typography>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Outlets
