import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClientProvider, QueryClient } from 'react-query'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ToastProvider from './context/toastContext'
import { LocationProvider } from './context/locationContext'
import { AddressContextProvider } from './context/addressContext'
import { SpeechProvider } from './context/speechContext'
import { CartContextProvider } from './context/cartContext'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import LoginProvider from 'context/loginContext'
import { UserContextProvider } from 'context/userContext'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'

const theme = createTheme({
  palette: {
    primary: {
      main: '#3A3A88',
      // main: '#3A3A88',
    },
  },
})

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <LoginProvider>
        <UserContextProvider>
          <ToastProvider>
            <SpeechProvider>
              <LocationProvider>
                <CartContextProvider>
                  <AddressContextProvider>
                    <HelmetProvider>
                      <BrowserRouter>
                        <App />
                      </BrowserRouter>
                    </HelmetProvider>
                  </AddressContextProvider>
                </CartContextProvider>
              </LocationProvider>
            </SpeechProvider>
          </ToastProvider>
        </UserContextProvider>
      </LoginProvider>
    </ThemeProvider>
  </QueryClientProvider>,
  // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
