import React, { useState, useEffect, useContext } from 'react'
import useStyles from './style'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import MuiLink from '@mui/material/Link'
import Pagination from '@mui/material/Pagination'
import useCancellablePromise from '../../../api/cancelRequest'
import { getAllFiltersRequest, getAllFilterValuesRequest } from '../../../api/product.api'
import { getCall, postCall } from '../../../api/axios'
import { ToastContext } from '../../../context/toastContext'
import { toast_actions, toast_types } from '../../shared/toast/utils/toast'
import {
  formatCustomizationGroups,
  formatCustomizations,
  initializeCustomizationState,
} from '../../application/product-list/product-details/utils'
import { CartContext } from '../../../context/cartContext'
// import { SearchContext } from '../../../context/searchContext'
import { CategoryContainer } from '../../category/subCategories/style'
import { useTheme } from '../../../context/newThemeContext'
import SearchProducts from '../../SearchProducts/searchProducts'
import { getOrCreateDeviceId, getUserId } from '../../../helper'
// import Spinner from 'components/Loader'
import { GridListViewWrapper } from '../../../styles/home'
import Spinner from 'components/Loader'
// import { BreadcrumbWrapper } from 'components/brand/products/style'

const WapProductList = () => {
  const { domain, category } = useParams()
  const classes = useStyles()
  const locationData = useLocation()
  const history = useHistory()
  //   const { locationData: deliveryAddressLocation } = useContext(SearchContext)
  const { fetchCartItems } = useContext(CartContext)
  const [setProductPayload] = useState(null)
  const [customization_state, setCustomizationState] = useState({})
  const [productLoading, setProductLoading] = useState(false)
  productLoading
  const [products, setProducts] = useState([])
  const [totalProductCount, setTotalProductCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    pageSize: 18,
    searchData: [],
  })
  const dispatch = useContext(ToastContext)
  const userId = getUserId()
  const [priceRange, setPriceRange] = useState('All Price')
  // HOOKS
  const { cancellablePromise } = useCancellablePromise()

  const useQuery = () => {
    const { search } = locationData
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useQuery()
  const categoryName = query.get('c')
  const subCategoryName = query.get('sc')
  const searchProductName = query.get('s')

  //   const getAllProducts = async (searchName) => {
  //     setIsLoading(true)
  //     try {
  //       let paginationData = Object.assign({}, JSON.parse(JSON.stringify(paginationModel)))

  //       paginationData.searchData = paginationData.searchData.filter((item) => item.selectedValues.length > 0)
  //       paginationData.searchData = paginationData.searchData.reduce(function (r, e) {
  //         r[e.code] = e.selectedValues.join()
  //         return r
  //       }, {})

  //       paginationData.searchData.page = paginationData.page
  //       paginationData.searchData.limit = paginationData.pageSize

  //       if (['Headset', 'Earphone'].includes(searchName)) {
  //         paginationData.searchData.categoryId = searchName || ''
  //       } else {
  //         paginationData.searchData.name = searchName || ''
  //       }

  //       if (subCategoryName) {
  //         paginationData.searchData.categoryIds = subCategoryName || ''
  //       }

  //       if (searchName) {
  //         if (priceRange !== 'All Price') {
  //           const res = priceRange.replace(/\D/g, '')
  //           paginationData.searchData.priceMax = res || ''
  //           paginationData.searchData.priceMin = 0
  //         }
  //       }

  //       const data = await cancellablePromise(getAllProductRequest(paginationData.searchData))
  //       setProducts(data.data)
  //       setTotalProductCount(data.count)
  //     } catch (err) {
  //       dispatch({
  //         type: toast_actions.ADD_TOAST,
  //         payload: {
  //           id: Math.floor(Math.random() * 100),
  //           type: toast_types.error,
  //           message: err?.response?.data?.error?.message,
  //         },
  //       })
  //     } finally {
  //       setIsLoading(false)
  //     }
  //   }

  const getFilterValues = async (attributeCode) => {
    try {
      const data = await cancellablePromise(getAllFilterValuesRequest(attributeCode, subCategoryName))
      let filterValues = data.data
      filterValues = filterValues.map((value) => {
        const createObj = {
          id: value,
          name: value,
        }
        return createObj
      })
      return filterValues
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  const getAllFilters = async () => {
    setIsLoading(true)
    try {
      const data = await cancellablePromise(getAllFiltersRequest(subCategoryName))
      let filtersData = data.data
      filtersData = filtersData.filter((item) => item.code !== 'size_chart')
      filtersData = Object.values(filtersData.reduce((acc, obj) => ({ ...acc, [obj.code]: obj }), {}))

      for (let filter of filtersData) {
        const values = await getFilterValues(filter.code)
        const findIndex = filtersData.findIndex((item) => item.code === filter.code)
        if (findIndex > -1) {
          filtersData[findIndex].options = values
          filtersData[findIndex].selectedValues = []
        }
      }

      let paginationData = Object.assign(JSON.parse(JSON.stringify(paginationModel)))
      paginationData.searchData = filtersData
      if (JSON.stringify(paginationModel) !== JSON.stringify(paginationData)) {
        setPaginationModel(paginationData)
      }
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (subCategoryName) {
      getAllFilters()
    }
  }, [subCategoryName])

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true)
      try {
        await getNewProducts()
      } catch (error) {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: error,
          },
        })
      } finally {
        setIsLoading(false)
      }
    }

    fetchProducts()
  }, [searchProductName, categoryName, subCategoryName])
  // useEffect(() => {
  //   fetchProducts(searchProductName) // Fetch based on query param
  // }, [searchProductName, locationData]) // Add dependencies as needed

  useEffect(() => {
    if (domain && category) {
      getNewProducts()
    }
  }, [domain, category])

  const capitalizedCategory = category.charAt(0).toUpperCase() + category.slice(1)
  const getNewProducts = async () => {
    setIsLoading(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/protocol/categories-items?domain=${domain}&category=${capitalizedCategory}`,
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      setProducts(data?.data)
      setTotalProductCount(data?.data?.count || 0)
    } catch (error) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: error,
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getNewProducts()
  }, [domain, category, paginationModel])

  const getProductDetails = async (productId) => {
    try {
      setProductLoading(true)
      const data = await cancellablePromise(
        getCall(`${process.env.REACT_APP_BASE_PROTOCOL_URL}/protocol/item-details?id=${productId}`),
      )
      setProductPayload(data)
      return data
    } catch (error) {
      error
    } finally {
      setProductLoading(false)
    }
  }

  const calculateSubtotal = () => {
    let subtotal = 0

    for (const level in customization_state) {
      const selectedOptions = customization_state[level].selected
      if (selectedOptions?.length > 0) {
        subtotal += selectedOptions.reduce((acc, option) => acc + option.price, 0)
      }
    }

    return subtotal
  }

  const getCustomizations = async (productPayload, customization_state) => {
    const { customisation_items } = productPayload
    const customizations = []
    const levels = Object.keys(customization_state)

    for (const level of levels) {
      const selectedItems = customization_state[level].selected

      for (const selectedItem of selectedItems) {
        let customization = customisation_items?.find((item) => item.local_id === selectedItem.id)

        if (customization) {
          customization = {
            ...customization,
            quantity: {
              count: 1,
            },
          }
          customizations.push(customization)
        }
      }
    }

    return customizations
  }

  const addToCart = async (productPayload, isDefault = false, navigate = false) => {
    setProductLoading(true)
    const deviceId = await getOrCreateDeviceId()

    const url = `/clientApis/v2/cart/${userId}/${deviceId}`
    // const url = `/clientApis/v2/cart/${user.id ? user.id : customCartId}`

    const subtotal = productPayload?.item_details?.price?.value + calculateSubtotal()

    const groups = await formatCustomizationGroups(productPayload.customisation_groups)
    const cus = await formatCustomizations(productPayload.customisation_items)
    const newState = await initializeCustomizationState(groups, cus, customization_state)

    getCustomizations(productPayload, isDefault ? newState : customization_state).then((customisations) => {
      const payload = {
        id: productPayload.id,
        local_id: productPayload.local_id,
        bpp_id: productPayload.bpp_details.bpp_id,
        bpp_uri: productPayload.context.bpp_uri,
        domain: productPayload.context.domain,
        quantity: {
          count: 1,
        },
        provider: {
          id: productPayload.bpp_details.bpp_id,
          locations: productPayload.locations,
          ...productPayload.provider_details,
        },
        product: {
          id: productPayload.id,
          subtotal,
          ...productPayload.item_details,
        },
        hasCustomisations:
          productPayload.hasOwn('customisation_groups') && productPayload.customisation_groups.length > 0,
      }

      if (customisations.length > 0) {
        payload.customisations = customisations
      }

      postCall(url, payload)
        .then(() => {
          fetchCartItems()
          setCustomizationState({})
          setProductLoading(false)
          if (navigate) history.push('/cart')
        })
        .catch((error) => {
          dispatch({
            type: toast_actions.ADD_TOAST,
            payload: {
              id: Math.floor(Math.random() * 100),
              type: toast_types.error,
              message: error?.response?.data?.error?.message,
            },
          })
          setProductLoading(false)
        })
    })
  }

  const updateQueryParams = () => {
    const params = new URLSearchParams({})
    if (searchProductName) {
      params.set('s', searchProductName)
    }

    if (categoryName) {
      params.set('c', categoryName)
    }

    history.replace({
      pathname: `/`,
      search: params.toString(),
    })
  }
  const { theme } = useTheme()
  return (
    <CategoryContainer theme={theme}>
      <Grid container spacing={3} className={classes.productContainer}>
        <GridListViewWrapper>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="breadcrumb-wrapper">
            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb">
                <MuiLink component={Link} underline="hover" color="inherit" to="/">
                  Home
                </MuiLink>
                {categoryName && (
                  <MuiLink
                    component="div"
                    underline="hover"
                    color="inherit"
                    onClick={updateQueryParams}
                    href={`/products?${
                      searchProductName ? `s=${searchProductName}&` : ''
                    }${categoryName ? `c=${categoryName}` : ''}`}
                  >
                    {categoryName}
                  </MuiLink>
                )}
                {(subCategoryName || searchProductName) && (
                  <Typography color="text.primary">{subCategoryName || searchProductName}</Typography>
                )}
              </Breadcrumbs>
            </div>
          </Grid>
        </GridListViewWrapper>
        {/* <BreadcrumbWrapper>
          <Breadcrumbs aria-label="breadcrumb">
            <MuiLink component={Link} underline="hover" color="inherit" to="/">
              <Typography className="text-color">Home</Typography>
            </MuiLink>
            <MuiLink component={Link} underline="hover" color="inherit" to="/providers">
              <Typography className="text-color">Providers</Typography>
            </MuiLink>
            {categoryName && (
              <MuiLink
                component="div"
                underline="hover"
                color="inherit"
                onClick={updateQueryParams}
                href={`/products?${
                  searchProductName ? `s=${searchProductName}&` : ''
                }${categoryName ? `c=${categoryName}` : ''}`}
              >
                {categoryName}
              </MuiLink>
            )}
            {(subCategoryName || searchProductName) && (
              <Typography color="text.primary">{subCategoryName || searchProductName}</Typography>
            )}
          </Breadcrumbs>
        </BreadcrumbWrapper> */}

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="products-wrapper">
          <div>
            {isLoading ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Spinner />
              </Grid>
            ) : (
              <>
                <SearchProducts
                  products={products}
                  setProducts={setProducts}
                  priceRange={priceRange}
                  setPriceRange={setPriceRange}
                  handleAddToCart={addToCart}
                  getProductDetails={getProductDetails}
                  isLoading={isLoading}
                />
              </>
            )}
          </div>
        </Grid>
        {products?.length > 0 && !isLoading && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.paginationContainer}>
            <Pagination
              className={classes.pagination}
              count={Math.ceil(totalProductCount / paginationModel.pageSize)}
              shape="rounded"
              color="primary"
              page={paginationModel.page}
              onChange={(event, page) => {
                event
                let paginationData = Object.assign({}, paginationModel)
                paginationData.page = page
                setPaginationModel(paginationData)
              }}
            />
          </Grid>
        )}
      </Grid>
    </CategoryContainer>
  )
}

export default WapProductList
